import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { LoginValidation } from '../validations/LoginValidation'
import { useAppDispatch } from '@/app/hooks'
import { LogInPropsInterface } from '@/features/auth/redux/types'
import { loginMutation } from '@/features/auth/redux/authAPI'
import { Button, FormControl, TextInput } from '@/components'
import { useValidation } from '@/utils/hooks/useValidation'

const LoginScreen = (): ReactNode => {
  const { t } = useTranslation(['auth', 'form', 'validation'])
  const { schema, defaultValues } = useValidation(new LoginValidation(), t)
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (data: LogInPropsInterface) => {
    try {
      await dispatch(loginMutation(data)).unwrap()
      enqueueSnackbar(t('auth:login.success'), { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(t('auth:login.error'), { variant: 'error' })
    }
  }

  return (
    <Fragment>
      <div className={'pb-24 flex justify-center'}>
        <img src={'/images/logo.png'} alt={'logo'} />
      </div>
      <div className={'flex flex-col gap-y-6'}>
        <Controller
          name={'email'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl name={'email'} errors={errors}>
              <TextInput
                label={t('form:labels.email')}
                isInvalid={!!error}
                type={'email'}
                placeholder={t('form:placeholders.email')}
                {...field}
              />
            </FormControl>
          )}
        />
        <Controller
          name={'password'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl name={'password'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                label={t('form:labels.password')}
                type={'password'}
                placeholder={t('form:placeholders.password')}
                {...field}
              />
            </FormControl>
          )}
        />
        <Button onClick={handleSubmit(onSubmit)}>
          {t('auth:login.button')}
        </Button>
      </div>
    </Fragment>
  )
}

export { LoginScreen }
