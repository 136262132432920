import {
  addDays,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  intlFormatDistance,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subYears,
} from 'date-fns'
import { pl } from 'date-fns/locale/pl'
import { parseDate } from '@internationalized/date'
import { RangeValue } from '@react-types/shared'
import { DateValue } from '@react-types/datepicker'
import { Nullable } from '@/utils/types'

class Carbon {
  private date: Date = new Date()

  constructor(date?: Date | string | number) {
    if (date) {
      this.parse(date)
    }
  }

  static datesToDatePicker(
    datesTuple?: Nullable<[string, string]>
  ): Nullable<RangeValue<DateValue>> {
    if (!datesTuple) return null

    const [startDateString, endDateString] = datesTuple

    return {
      start: parseDate(
        format(new Date(startDateString), 'yyyy-MM-dd').toString()
      ),
      end: parseDate(format(new Date(endDateString), 'yyyy-MM-dd').toString()),
    }
  }

  static dateRangeToAPIFormat(
    range: RangeValue<DateValue>
  ): Nullable<[string, string]> {
    if (!range || !range.start || !range.end) return null

    return [range.start.toString(), range.end.toString()]
  }

  now(): Date {
    return this.date
  }

  addDays(days: number): this {
    this.date = addDays(this.date, days)

    return this
  }

  subDays(days: number): this {
    this.date = subDays(this.date, days)

    return this
  }

  subMonths(months: number): this {
    this.date = subMonths(this.date, months)

    return this
  }

  subYears(years: number): this {
    this.date = subYears(this.date, years)

    return this
  }

  subHours(hours: number): this {
    this.date = subHours(this.date, hours)

    return this
  }

  subMinutes(minutes: number): this {
    this.date = subMinutes(this.date, minutes)

    return this
  }

  format(formatDate: string): string {
    return format(this.date, formatDate, {
      locale: pl,
    })
  }

  getDate(): Date {
    return this.date
  }

  parse(date: string | Date | number): this {
    if (date instanceof Date) {
      this.date = date
    } else {
      try {
        if (typeof date === 'number') {
          this.date = new Date(date * 1000)
        } else {
          this.date = new Date(date)
        }
      } catch (error) {
        throw new Error('Invalid date')
      }
    }

    return this
  }

  toISOString(): string {
    return this.date.toISOString()
  }

  toLocaleString(): string {
    return this.date.toLocaleString('pl-PL', {
      timeZone: 'Europe/Warsaw',
    })
  }

  toDate(): string {
    return this.format('dd.MM.yyyy')
  }

  toDateTimeLocal(): string {
    return this.format('dd.MM.yyyy HH:mm')
  }

  gt(date: Date): boolean {
    return this.date > date
  }

  gte(date: Date): boolean {
    return this.date >= date
  }

  lt(date: Date): boolean {
    return this.date < date
  }

  toInputWithDateTime(): string {
    return this.date.toISOString().slice(0, 16)
  }

  toHumanDate(): string {
    return intlFormatDistance(this.date, new Date(), {
      locale: 'pl',
    })
  }

  isToday(): boolean {
    return this.date === new Date()
  }

  lte(date: Date) {
    return this.date <= date
  }

  isFeature(): boolean {
    return this.date > new Date()
  }

  startOfMonth(): this {
    this.date = startOfMonth(this.date)

    return this
  }

  endOfMonth(): this {
    this.date = endOfMonth(this.date)

    return this
  }

  startOfQuarter(): this {
    this.date = startOfQuarter(this.date)

    return this
  }

  endOfQuarter(): this {
    this.date = endOfQuarter(this.date)

    return this
  }

  startOfYear(): this {
    this.date = startOfYear(this.date)

    return this
  }

  endOfYear(): this {
    this.date = endOfYear(this.date)

    return this
  }

  toDatePickerValue(): string {
    return this.format('yyyy-MM-dd')
  }
}

export { Carbon }
