import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import _ from 'lodash'
import { useGetRolesQuery } from '../../roleAPI'
import { RoleInterface } from '../../types'
import { Query } from '@/utils/query'
import { usePagination } from '@/utils/hooks'

const useInfiniteRolesQuery = (search: string | number | undefined) => {
  const [{ pageIndex }, setPagination] = usePagination()
  const query = useMemo(() => {
    const q = new Query().page(pageIndex)

    if (search) {
      q.where('name', search)
    }

    return q.url()
  }, [pageIndex, search])
  const debouncedQuery = useDebounce(query, 500)
  const { data, isLoading } = useGetRolesQuery(debouncedQuery)
  const hasMore = useMemo(
    () =>
      data ? data.pagination.current_page < data.pagination.total_pages : false,
    [data]
  )
  const [roles, setRoles] = useState<RoleInterface[]>([])

  useEffect(() => {
    if (data) {
      setRoles((prevRoles) => _.uniqBy([...prevRoles, ...data.data], 'id'))
    }
  }, [data])

  const handleNext = () => {
    if (hasMore) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: prevPagination.pageIndex + 1,
      }))
    }
  }

  return {
    data: roles,
    isLoading,
    hasMore,
    handleNext,
  }
}

export { useInfiniteRolesQuery }
