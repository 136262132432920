import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { UserInterface } from '@/features/users/redux/types'
import { Carbon } from '@/utils/carbon'
import { Card } from '@/components'

type Props = Omit<UserInterface, 'role' | 'company'>

export const PersonalData: React.FC<Props> = (data): React.ReactNode => {
  const { t } = useTranslation(['form'])

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {t('users:show.personal_data')}
        </span>
        <div className={'flex flex-col gap-y-2'}>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>{t('form:labels.name')}</span>
            <span>{data.name}</span>
          </div>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>{t('form:labels.email')}</span>
            <span>{data.email}</span>
          </div>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>{t('form:labels.user_type')}</span>
            <span>{t(`users:user_types.${data.type}`)}</span>
          </div>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>
              {t('form:labels.email_verified_at')}
            </span>
            <span>
              {data.email_verified_at
                ? new Carbon()
                    .parse(data.email_verified_at)
                    .format('dd.MM.yyyy HH:mm')
                : '-'}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}
