import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyInterface } from '@/features/companies/redux/types'
import { Card } from '@/components'

type Props = {
  company: CompanyInterface
}

export const CompanyData: React.FC<Props> = ({ company }): React.ReactNode => {
  const { t } = useTranslation(['form'])

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {t('users:show.company_data')}
        </span>
        <div className={'flex flex-col gap-y-2'}>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>
              {t('form:labels.company_name')}
            </span>
            <span>{company.name}</span>
          </div>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>
              {t('form:labels.phone_number')}
            </span>
            <span>{company.phone_number}</span>
          </div>
          {company.category && (
            <div className={'grid grid-cols-2 gap-4'}>
              <span className={'font-medium'}>
                {t('form:labels.declared_sales_category')}
              </span>
              <div className={'flex gap-x-2 items-center'}>
                <img
                  src={company.category.url || ''}
                  alt={company.category.name}
                  className={'size-4'}
                />
                <span>{company.category.name}</span>
              </div>
            </div>
          )}
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>
              {t('form:labels.description')}
            </span>
            {company.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: company.description.substring(0, 50),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}
