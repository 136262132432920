import { Input as BaseInput, InputProps } from '@nextui-org/input'
import { forwardRef } from 'react'
import _ from 'lodash'
import { cn } from '@/utils/helpers/cn'

const TextInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { classNames, isInvalid, ...rest } = props

  return (
    <BaseInput
      labelPlacement={'outside'}
      classNames={{
        label: cn('text-sm font-bold text-neutral-900', classNames?.label, {
          '!text-foundation-danger': isInvalid,
        }),
        inputWrapper: cn(
          'border border-opaque bg-white font-work-sans text-[12px] leading-[20px] shadow-none',
          'data-[hover=true]:bg-white group-data-[focus=true]:bg-white group-data-[hover=true]:bg-white',
          {
            '!border-foundation-danger !text-foundation-danger': isInvalid,
          },
          classNames?.inputWrapper
        ),
        input: cn(
          'font-work-sans text-sm leading-[20px] placeholder-content-tertiary',
          classNames?.input
        ),
        ..._.omit(classNames, ['inputWrapper', 'input', 'label']),
      }}
      size={'lg'}
      radius={'sm'}
      isInvalid={isInvalid}
      {...rest}
      ref={ref}
    />
  )
})

TextInput.displayName = 'TextInput'

export { TextInput }
