import { knwAPI } from '@/utils/api/knw'
import {
  GetUserResponseInterface,
  GetUsersResponseInterface,
  StoreUserPropsInterface,
  UpdateUserPropsInterface,
} from '@/features/users/redux/types'
import { setPaginatedCache, setSingleObjectCache } from '@/utils/api/rtkHelper'

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
  useStoreUserMutation,
  useUpdateUserMutation,
} = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponseInterface['data'], string>({
      query: (query) => `/api/admin/users?${query}`,
      transformResponse: (response: GetUsersResponseInterface) => response.data,
      providesTags: (result) => setPaginatedCache('User', result),
    }),
    getUser: builder.query<GetUserResponseInterface['data'], number | string>({
      query: (id) => `/api/admin/users/${id}`,
      transformResponse: (response: GetUserResponseInterface) => response.data,
      providesTags: (result) => setSingleObjectCache('User', result),
    }),
    deleteUser: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `/api/admin/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    storeUser: builder.mutation<void, StoreUserPropsInterface>({
      query: (body) => ({
        url: '/api/admin/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    updateUser: builder.mutation<
      void,
      { id: string | number } & UpdateUserPropsInterface
    >({
      query: ({ id, ...rest }) => ({
        url: `/api/admin/users/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'User', id: 'LIST' },
        { type: 'User', id },
      ],
    }),
  }),
})
