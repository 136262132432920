import { AnyObject, Maybe } from 'yup'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import {
  BackendValidationErrorInterface,
  ValidationErrors,
  ValidationInterface,
} from './useValidation.types'

const useValidation = <T extends Maybe<AnyObject>>(
  validateClass: ValidationInterface<T>,
  t: TFunction
) => {
  const validation = useMemo(() => {
    return {
      schema: validateClass.rules(t),
      defaultValues: validateClass.defaultValues(),
    }
  }, [t, validateClass])

  const resolveValidationErrors = (
    errors: BackendValidationErrorInterface<T>
  ): ValidationErrors<T> => {
    const validationErrors: ValidationErrors<T> = {} as ValidationErrors<T>

    for (const [key, value] of Object.entries(errors.errors)) {
      validationErrors[key as keyof T] = (value as string[])[0]
    }

    return validationErrors
  }

  return {
    schema: validation.schema,
    defaultValues: validation.defaultValues,
    resolveValidationErrors,
  }
}

export { useValidation }
