import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { StoreAgreementValidation } from './validations/StoreAgreementValidation'
import { FormInterface } from '@/app/types'
import {
  AgreementInterface,
  StoreAgreementRequestInterface,
} from '@/features/agreements/redux/types'
import { useValidation } from '@/utils/hooks/useValidation'
import {
  Button,
  Checkbox,
  FormControl,
  Label,
  SelectAgreementsv2,
  Textarea,
  TextInput,
} from '@/components'

const Form: FC<
  FormInterface<StoreAgreementRequestInterface, AgreementInterface>
> = ({ onSubmit }): ReactNode => {
  const { t } = useTranslation(['form', 'validation', 'utils'])
  const { schema, defaultValues } = useValidation(
    new StoreAgreementValidation(),
    t
  )

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<StoreAgreementRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const handleSelectAgreements = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value

    if (value.length) {
      setValue(
        'groups',
        value.split(',').map((id) => Number(id))
      )
    } else {
      setValue('groups', [])
    }
  }

  return (
    <div className={'flex flex-col'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'flex flex-col gap-y-6'}
      >
        <div className={'grid grid-cols-3 gap-4'}>
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'name'} errors={errors}>
                <TextInput
                  isInvalid={!!error}
                  isRequired
                  label={t('form:labels.name')}
                  {...field}
                />
              </FormControl>
            )}
            name={'name'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <SelectAgreementsv2
                onChange={handleSelectAgreements}
                selectedKeys={field.value}
                label={t('form:labels.agreement_groups')}
              />
            )}
            name={'groups'}
            control={control}
          />
          <Controller
            render={({ field, formState: { errors } }) => (
              <FormControl name={'file'} errors={errors}>
                <div className={'flex flex-col gap-y-1'}>
                  <Label label={t('form:labels.file')} />
                  <input
                    type={'file'}
                    accept={'application/pdf, application/msword, text/plain'}
                    name={field.name}
                    onChange={(e) => {
                      e.target.files
                        ? field.onChange(e.target.files[0])
                        : field.onChange(null)
                    }}
                  />
                </div>
              </FormControl>
            )}
            name={'file'}
            control={control}
          />
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl
                className={'col-span-3'}
                name={'content'}
                errors={errors}
              >
                <Textarea
                  isInvalid={!!error}
                  minRows={5}
                  label={t('form:labels.content')}
                  {...field}
                />
              </FormControl>
            )}
            name={'content'}
            control={control}
          />
          <div className={'flex flex-col gap-y-1'}>
            <Controller
              render={({ field, formState: { errors } }) => (
                <FormControl name={'required'} errors={errors}>
                  <Checkbox
                    label={t('form:labels.required')}
                    checked={field.value}
                    onChange={field.onChange}
                    name={field.name}
                  />
                </FormControl>
              )}
              name={'required'}
              control={control}
            />
            <Controller
              render={({ field, formState: { errors } }) => (
                <FormControl name={'visible'} errors={errors}>
                  <Checkbox
                    label={t('form:labels.visible')}
                    checked={field.value}
                    name={field.name}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
              name={'visible'}
              control={control}
            />
          </div>
        </div>
        <div className={'flex pt-4'}>
          <Button color={'primary'} type={'submit'}>
            {t('form:buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export { Form }
