import { TFunction } from 'i18next'
import * as yup from 'yup'
import { StoreCategoryPropsInterface } from '@/features/categories/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation'

class StoreCategoryValidation
  implements ValidationInterface<StoreCategoryPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<StoreCategoryPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      icon: yup.mixed<File>().required(t('validation:required')),
      color: yup
        .string()
        .nullable()
        .matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, t('validation:color')),
    })
  }

  defaultValues(): StoreCategoryPropsInterface {
    return {
      name: '',
      icon: null,
    }
  }
}

export { StoreCategoryValidation }
