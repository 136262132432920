import {
  GetVendingMachineResponseInterface,
  GetVendingMachinesResponseInterface,
  UpdateVendingMachineRequestInterface,
  VendingMachineInterface,
} from '../types'
import { knwAPI } from '@/utils/api/knw'
import { PaginatedResponseInterface } from '@/app/types'
import { setPaginatedCache, setSingleObjectCache } from '@/utils/api/rtkHelper'

const vendingMachineRepository = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getVendingMachines: builder.query<
      PaginatedResponseInterface<VendingMachineInterface>,
      string
    >({
      query: (query) => `/api/admin/vending-machines?${query}`,
      transformResponse: (response: GetVendingMachinesResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache('VendingMachine', result),
    }),
    getVendingMachine: builder.query<VendingMachineInterface, number | string>({
      query: (id) => `/api/admin/vending-machines/${id}`,
      transformResponse: (response: GetVendingMachineResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache('VendingMachine', result),
    }),
    updateVendingMachine: builder.mutation<
      void,
      { data: UpdateVendingMachineRequestInterface; id: number }
    >({
      query: ({ data, id }) => ({
        url: `/api/admin/vending-machines/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, _, { id }) => [
        { type: 'VendingMachine', id },
        {
          type: 'VendingMachine',
          id: 'LIST',
        },
      ],
    }),
    deleteVendingMachine: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/api/admin/vending-machines/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [
        {
          type: 'VendingMachine',
          id: 'LIST',
        },
      ],
    }),
  }),
})

export const {
  useGetVendingMachinesQuery,
  useGetVendingMachineQuery,
  useUpdateVendingMachineMutation,
  useDeleteVendingMachineMutation,
} = vendingMachineRepository
