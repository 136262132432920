import { Select as BaseSelect, SelectProps } from '@nextui-org/select'
import { omit } from 'lodash'
import { cn } from '@/utils/helpers/cn'

const Selectv2 = <T extends object>(props: SelectProps<T>) => {
  const { classNames, children, listboxProps, ...rest } = props

  return (
    <BaseSelect
      labelPlacement={'outside'}
      classNames={{
        label: cn(
          'font-manrope text-sm font-bold text-neutral-900',
          classNames?.label
        ),
        trigger: cn(
          'border border-opaque bg-white font-work-sans text-sm leading-[20px] shadow-none data-[hover=true]:bg-white',
          classNames?.trigger
        ),
        innerWrapper: cn('bg-white', classNames?.innerWrapper),
        value: cn('font-work-sans text-sm leading-[20px]', classNames?.value),
        ...omit(classNames, 'trigger', 'innerWrapper', 'value'),
      }}
      radius={'sm'}
      popoverProps={{
        radius: 'sm',
      }}
      listboxProps={{
        itemClasses: {
          base: cn(
            [
              'data-[selectable=true]:hover:bg-foundation-primary-a data-[selectable=true]:hover:text-white',
              'data-[selectable=true]:focus:bg-foundation-primary-a data-[selectable=true]:focus:text-white',
            ],
            listboxProps?.itemClasses?.base
          ),
          ...omit(listboxProps?.itemClasses, 'base'),
        },
        ...omit(listboxProps, 'itemClasses'),
      }}
      size={'lg'}
      {...rest}
    >
      {children}
    </BaseSelect>
  )
}

export { Selectv2 }
