import {
  EventInterface,
  GetEventResponseInterface,
  GetEventsResponseInterface,
} from '../types'
import { EventStatusEnum } from '../enums'
import { knwAPI } from '@/utils/api/knw'
import { PaginatedResponseInterface } from '@/app/types'
import { setPaginatedCache, setSingleObjectCache } from '@/utils/api/rtkHelper'

const eventRepository = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<
      PaginatedResponseInterface<EventInterface>,
      string
    >({
      query: (query) => `/api/admin/events?${query}`,
      transformResponse: (response: GetEventsResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache('Event', result),
    }),
    getEvent: builder.query<EventInterface, number | string>({
      query: (id) => `/api/admin/events/${id}`,
      transformResponse: (response: GetEventResponseInterface) => response.data,
      providesTags: (result) => setSingleObjectCache('Event', result),
    }),
    changeStatus: builder.mutation<
      void,
      { id: number; status: EventStatusEnum }
    >({
      query: ({ id, status }) => ({
        url: `/api/admin/events/${id}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'Event', id },
        { type: 'Event', id: 'List' },
      ],
    }),
  }),
})

export const { useGetEventsQuery, useGetEventQuery, useChangeStatusMutation } =
  eventRepository
