import { TFunction } from 'i18next'
import * as yup from 'yup'
import _ from 'lodash'
import { StoreAgreementRequestInterface } from '@/features/agreements/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class StoreAgreementValidation
  implements ValidationInterface<StoreAgreementRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<StoreAgreementRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      required: yup.boolean().required(t('validation:required')),
      visible: yup.boolean().required(t('validation:required')),
      name: yup.string().required(t('validation:required')),
      content: yup.string().required(t('validation:required')),
      file: yup
        .mixed<File>()
        .required(t('validation:required'))
        .test(
          'file-extension',
          t('validation:file_extension', {
            allowedExtensions: 'pdf, doc ,docx, txt',
          }),
          function (value) {
            if (_.isNil(value)) return true

            const allowedExtensions = ['pdf', 'doc', 'docx', 'txt']
            const fileExtension = value.name.split('.').pop()

            return allowedExtensions.includes(fileExtension || '')
          }
        ),
      groups: yup.array().required(t('validation:required')),
    })
  }

  defaultValues(): StoreAgreementRequestInterface {
    return {
      required: false,
      visible: false,
      name: '',
      content: '',
      file: null,
      groups: [],
    }
  }
}

export { StoreAgreementValidation }
