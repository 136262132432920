import { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { SelectItem } from '@nextui-org/react'
import { Card, FormControl, Selectv2 } from '@/components'
import {
  PostInterface,
  UpdatePostRequestInterface,
} from '@/features/posts/redux/types'
import { useGetAddressesQuery } from '@/features/companies'

type Props = {
  data?: PostInterface
}

const LocationForm: FC<Props> = ({ data }): ReactNode => {
  const { t } = useTranslation(['posts'])
  const { control, setValue } = useFormContext<UpdatePostRequestInterface>()
  const { data: addresses } = useGetAddressesQuery(data!.user!.company!.id)

  useEffect(() => {
    if (data) {
      setValue('address_id', data.address_id)
    }
  }, [data])

  if (!data) {
    return <span></span>
  }

  return (
    <Card className={'flex flex-col gap-6'}>
      <span className={'font-medium'}>{t('posts:edit.address.title')}</span>
      <div className={'flex flex-col gap-y-4'}>
        <MapContainer
          center={[data.address.location.lat, data.address.location.lon]}
          zoom={13}
          scrollWheelZoom={false}
          className={'min-h-[300px]'}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <Marker
            position={[data.address.location.lat, data.address.location.lon]}
          >
            <Popup></Popup>
          </Marker>
        </MapContainer>
        <Controller
          render={({ field, fieldState: { error }, formState: { errors } }) => (
            <FormControl name={field.name} errors={errors}>
              <Selectv2
                label={t('form:labels.address')}
                placeholder={t('form:placeholders.address')}
                selectedKeys={[field.value]}
                onChange={field.onChange}
                isInvalid={!!error}
                items={addresses ? addresses : []}
              >
                {(address) => (
                  <SelectItem key={address.id}>{address.name}</SelectItem>
                )}
              </Selectv2>
            </FormControl>
          )}
          name={'address_id'}
          control={control}
        />
      </div>
    </Card>
  )
}

export { LocationForm }
