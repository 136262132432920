import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Details } from './components'
import {
  useDeleteCategoryMutation,
  useGetCategoryQuery,
} from '@/features/categories/redux/categoryAPI'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { usePermissions } from '@/utils/hooks/usePermission/usePermission'
import { isFetchBaseQueryErrorWithCode } from '@/utils/api/rtkHelper'
import { ResponseCodeEnum } from '@/app/types'
import { Button } from '@/components'

export const CategoryDetailsScreen = (): React.ReactNode => {
  const { t } = useTranslation(['categories', 'form'])
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data } = useGetCategoryQuery(id)
  const { hasPermission } = usePermissions()
  const [deleteCategory] = useDeleteCategoryMutation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  if (!data) {
    return <div>Loading...</div>
  }

  const handleDelete = async () => {
    try {
      await deleteCategory(id).unwrap()
      enqueueSnackbar(t('categories:list.deleted'), { variant: 'success' })
      navigate('/categories')
    } catch (error) {
      if (
        isFetchBaseQueryErrorWithCode(error) &&
        error.data.code === ResponseCodeEnum.CATEGORY_ASSIGNED_TO_POST
      ) {
        enqueueSnackbar(t('categories:list.assigned_to_post'), {
          variant: 'error',
        })
      }
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <div className={'flex justify-between items-center'}>
        <h3 className={'text-2xl font-medium'}>{t('categories:show.title')}</h3>
        <div className={'flex gap-x-2'}>
          {hasPermission(PermissionEnum.CATEGORY_SAVE) && (
            <Button href={`/categories/${id}/edit`} as='a'>
              {t('form:buttons.edit')}
            </Button>
          )}
          {hasPermission(PermissionEnum.CATEGORY_DELETE) && (
            <Button onClick={handleDelete} color={'danger'}>
              {t('form:buttons.delete')}
            </Button>
          )}
        </div>
      </div>
      <div className={'grid lg:grid-cols-2 grid-cols-1 gap-4'}>
        <Details data={data} />
        {data.parent && <Details parent data={data.parent} />}
      </div>
    </div>
  )
}
