import * as React from 'react'
import { useMemo, useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import { AutocompleteItem } from '@nextui-org/react'
import { Key } from '@react-types/shared'
import { Autocomplete } from '../Autocomplete'
import { SearchProps } from './AsyncSelect.types'
import { Query } from '@/utils/query'
import { useSearchProductQuery } from '@/features/products/redux/productAPI'

const SearchProduct: React.FC<SearchProps> = ({
  query: _query,
  selectedKey: _value,
  initialInputValue,
  ...rest
}): React.ReactNode => {
  const [value, setValue] = useState<string>(initialInputValue ?? '')
  const debouncedValue = useDebounce(value, 500)
  const query = useMemo(() => {
    const q = _query ?? new Query()

    if (debouncedValue) {
      q.where('name', debouncedValue)
    }

    if (_value && initialInputValue === value) {
      q.where('id', _value as number | string)
    }

    return q.url()
  }, [debouncedValue, _query, _value])
  const { data = [], isLoading } = useSearchProductQuery(query)

  const handleSelectionChange = (key: Key | null) => {
    if (key) {
      setValue(data.find((item) => item.id === Number(key))?.name ?? '')
    }

    rest.onSelectionChange?.(key)
  }

  return (
    <Autocomplete
      inputValue={value}
      onInputChange={setValue}
      items={data}
      isLoading={isLoading}
      selectedKey={_value?.toString()}
      {...rest}
      onSelectionChange={handleSelectionChange}
    >
      {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
    </Autocomplete>
  )
}

export { SearchProduct }
