import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'
import { SaveIntegrationRequestInterface } from '@/features/integrations/redux/types'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'

class SaveIntegrationValidation
  implements ValidationInterface<SaveIntegrationRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<SaveIntegrationRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      ip_addresses: yup
        .array()
        .of(yup.string().required(t('validation:required')))
        .required(t('validation:required'))
        .transform((value) => (value ? value : [])),
      permissions: yup
        .array()
        .of(yup.mixed<PermissionEnum>().required(t('validation:required')))
        .required(t('validation:required')),
      token: yup.string().nullable(),
    })
  }

  defaultValues(): SaveIntegrationRequestInterface {
    return {
      name: '',
      ip_addresses: [],
      permissions: [],
      token: null,
    }
  }
}

export { SaveIntegrationValidation }
