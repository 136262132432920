import React from 'react'
import { LabelTypeInterface } from './Label.types'

const Label: React.FC<LabelTypeInterface> = ({ label, isRequired }) => (
  <span className={'text-neutral-900'}>
    {label} {isRequired && <span className={'text-danger'}>*</span>}
  </span>
)

export { Label }
