import { forwardRef } from 'react'
import { Textarea as BaseTextarea, TextAreaProps } from '@nextui-org/input'
import { omit } from 'lodash'
import { cn } from '@/utils/helpers/cn'

const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const { classNames, isInvalid, ...rest } = props

    return (
      <BaseTextarea
        labelPlacement={'outside'}
        classNames={{
          label: cn(
            'font-manrope text-sm font-bold text-neutral-900',
            classNames?.label
          ),
          inputWrapper: cn(
            'border border-opaque bg-white font-work-sans text-[12px] leading-[20px] shadow-none data-[hover=true]:bg-white group-data-[focus=true]:bg-white',
            {
              'border-foundation-danger text-foundation-danger': isInvalid,
            },
            classNames?.base
          ),
          input: cn(
            'font-work-sans text-[14px] leading-[20px] placeholder-content-tertiary',
            classNames?.input
          ),
          description: cn(
            'font-work-sans text-[10px] leading-[14px] text-content-tertiary',
            classNames?.description
          ),
          ...omit(classNames, 'label', 'input', 'base', 'description'),
        }}
        isInvalid={isInvalid}
        ref={ref}
        {...rest}
      />
    )
  }
)

Textarea.displayName = 'Textarea'

export { Textarea }
