import { forwardRef } from 'react'
import { Label } from '../Label'
import { CheckboxTypes } from './Checkbox.types'
import { cn } from '@/utils/helpers/cn'

const Checkbox = forwardRef<HTMLInputElement, CheckboxTypes>((props, ref) => {
  const { label, className, ...rest } = props
  const classNames = cn(className, 'w-[16px] h-[16px] accent-primary')

  return (
    <div className={'flex items-center gap-2'}>
      <input type={'checkbox'} className={classNames} {...rest} ref={ref} />
      {label && <Label label={label} />}
    </div>
  )
})

Checkbox.displayName = 'Checkbox'

export { Checkbox }
