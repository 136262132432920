import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack'
import { AgreementInterface } from '@/features/agreements/redux/types'
import {
  useDeleteAgreementMutation,
  useGetAgreementsQuery,
} from '@/features/agreements/redux/agreementAPI'
import { ActionStack, baseActionStack, BaseTable, Button } from '@/components'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'

const columnHelper = createColumnHelper<AgreementInterface>()

const columns = (t: TFunction, onDelete: (id: number) => void) => [
  columnHelper.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
  }),
  columnHelper.accessor('required', {
    id: 'required',
    header: t('form:labels.required'),
    cell: ({ row }) => (row.original.required ? t('utils:yes') : t('utils:no')),
  }),
  columnHelper.accessor('visible', {
    id: 'visible',
    header: t('form:labels.visible'),
    cell: ({ row }) => (row.original.visible ? t('utils:yes') : t('utils:no')),
  }),
  columnHelper.accessor('groups', {
    id: 'groups',
    header: t('form:labels.agreement_groups'),
    cell: ({ row }) =>
      row.original.groups
        ?.map((group) => t(`utils:agreement_groups.${group.name}`))
        .join(', '),
  }),
  columnHelper.accessor('url', {
    id: 'icon',
    header: t('form:labels.file'),
    cell: ({ row }) => (
      <Button
        variant={'light'}
        size={'sm'}
        isIconOnly
        onClick={() => window.open(row.original.url, '_blank')}
      >
        <FontAwesomeIcon icon={faDownload} />
      </Button>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDelete: {
            action: () => onDelete(row.original.id),
            permission: PermissionEnum.AGREEMENT_DELETE,
          },
        })}
      />
    ),
  }),
]

export const Table = (): React.ReactNode => {
  const [data, setData] = useState<AgreementInterface[]>([])
  const { data: apiData } = useGetAgreementsQuery()
  const { t } = useTranslation(['form', 'utils'])
  const [deleteAgreement] = useDeleteAgreementMutation()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (apiData) {
      setData(apiData)
    }
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteAgreement(id).unwrap()
      enqueueSnackbar(t('agreements:list.deleted'), { variant: 'success' })
    } catch (error) {
      // empty block
    }
  }

  const table = useReactTable({
    columns: columns(t, handleDelete),
    data: data,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={'flex w-full'}>
      <BaseTable table={table} />
    </div>
  )
}
