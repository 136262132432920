import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash'
import { NominatimSearchResponseInterface } from '@/app/types'

const url = import.meta.env.VITE_NOMINATIM_API_URL

const nominatimAPI = createApi({
  tagTypes: [],
  reducerPath: 'nomiatimAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
  }),
  endpoints: (builder) => ({
    searchCity: builder.query<NominatimSearchResponseInterface[], string>({
      query: (query) => `/search.php?format=json&addressdetails=1&q=${query}`,
      transformResponse: (response: NominatimSearchResponseInterface[]) => {
        return response
          .filter(
            (item) =>
              (item.address.city ||
                item.address.village ||
                item.address.town) &&
              !_.isEmpty(item.address.state) &&
              !_.isEmpty(item.address.country)
          )
          .map((item) => ({
            ...item,
            label: `${
              item.address.city || item.address.village || item.address.town
            }, ${item.address.state}, ${item.address.country}`,
          }))
      },
    }),
  }),
})

export const { useLazySearchCityQuery, useSearchCityQuery } = nominatimAPI

export { nominatimAPI }
