import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { BasicForm } from './components'
import {
  useDeletePostMutation,
  useGetPostQuery,
} from '@/features/posts/redux/postAPI'
import { Button } from '@/components'

export const PostsEditScreen = (): React.ReactNode => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const { t } = useTranslation(['posts'])
  const { data } = useGetPostQuery(id)
  const [deletePost] = useDeletePostMutation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleDeletePost = async () => {
    try {
      await deletePost(id).unwrap()
      enqueueSnackbar(t('posts:list.deleted'), { variant: 'success' })
      navigate('/posts')
    } catch (e) {
      enqueueSnackbar(t('utils:errors.something_went_wrong'), {
        variant: 'error',
      })
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-6'}>
      <div className={'flex justify-between'}>
        <span className={'text-2xl font-medium'}>{t('posts:edit.title')}</span>
        <Button color={'danger'} onClick={handleDeletePost}>
          {t('posts:edit.delete')}
        </Button>
      </div>
      {data && <BasicForm id={id} data={data} />}
    </div>
  )
}
