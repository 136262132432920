'use client'

import { forwardRef } from 'react'
import {
  Button as BaseButton,
  ButtonProps,
  extendVariants,
} from '@nextui-org/react'

const CustomButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <BaseButton ref={ref} radius={'sm'} {...props} />
  }
)

CustomButton.displayName = 'Button'

const Button = extendVariants(CustomButton, {
  variants: {
    color: {
      success: 'bg-foundation-accent text-content-inverse-primary',
      primary: 'text-content-inverse-primary bg-foundation-info',
      danger: 'bg-foundation-danger text-content-inverse-primary',
      tertiary: 'bg-background-secondary text-content-primary',
      inverseSecondary: 'bg-content-inverse-secondary text-content-primary',
      backgroundTertiary: 'bg-background-tertiary text-content-primary',
      cancel: 'text-content-primary bg-content-inverse-secondary',
    },
    size: {
      xs: 'min-w-unit-6 h-unit-6',
      sm: 'px-0.5 py-0.5',
      md: 'px-6 py-3 font-medium',
    },
    radius: {
      full: 'rounded-[100px]',
    },
    variant: {
      text: 'bg-transparent p-0',
    },
  },
  defaultVariants: {
    color: 'primary',
    size: 'md',
    className: 'font-medium text-base',
  },
})

export { Button }
