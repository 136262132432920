import { useCallback } from 'react'
import { useGetMeQuery } from '@/features/auth/redux/authAPI'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'

export const usePermissions = () => {
  const { data: authMe } = useGetMeQuery()

  const hasPermission = useCallback(
    (permission: PermissionEnum) => {
      return authMe?.permissions.includes(permission)
    },
    [authMe?.permissions]
  )

  return {
    hasPermission,
  }
}
