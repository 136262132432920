import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ForgotPasswordPropsInterface } from '@/features/auth/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class ForgotPasswordValidation
  implements ValidationInterface<ForgotPasswordPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<ForgotPasswordPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      email: yup
        .string()
        .required(t('validation:required'))
        .email(t('validation:email_invalid')),
    })
  }

  defaultValues(): ForgotPasswordPropsInterface {
    return {
      email: '',
    }
  }
}

export { ForgotPasswordValidation }
