import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormTypes } from './Form.types'
import {
  StoreCategoryPropsInterface,
  UpdateCategoryPropsInterface,
} from '@/features/categories/redux/types'
import { useValidation } from '@/utils/hooks/useValidation'
import { Button, FormControl, Label, TextInput } from '@/components'

export const Form: React.FC<FormTypes> = ({
  data,
  onSubmit,
  validation,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'validation'])
  const { schema, defaultValues } = useValidation(new validation(), t)
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<StoreCategoryPropsInterface | UpdateCategoryPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const file = watch('icon')
  const fileUrl = useMemo(
    () =>
      file ? URL.createObjectURL(file) : data && data.url ? data.url : null,
    [file, data]
  )

  useEffect(() => {
    if (data) {
      setValue('name', data.name)

      if (data.color) {
        setValue('color', data.color)
      }
    }
  }, [data])

  return (
    <div className={'flex flex-col gap-y-6'}>
      <div className={'grid grid-cols-3 2xl:grid-cols-4 gap-4'}>
        <Controller
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'name'} errors={errors}>
              <TextInput
                placeholder={t('form:placeholders.name')}
                isInvalid={!!error}
                label={t('form:labels.name')}
                {...field}
              />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({ field }) => (
            <FormControl name={field.name} errors={errors}>
              <TextInput
                {...field}
                label={t('form:labels.color')}
                placeholder={t('form:placeholders.color')}
                value={field.value ?? ''}
                endContent={
                  <input type={'color'} {...field} value={field.value ?? ''} />
                }
              />
            </FormControl>
          )}
          name={'color'}
          control={control}
        />
        <Controller
          render={({ field, formState: { errors } }) => (
            <FormControl
              name={'icon'}
              errors={errors}
              className={'flex flex-col gap-y-1'}
            >
              <Label label={t('form:labels.icon')} />
              <input
                type={'file'}
                accept={'image/*'}
                name={field.name}
                onChange={(e) => {
                  e.target.files
                    ? field.onChange(e.target.files[0])
                    : field.onChange(null)
                }}
              />
            </FormControl>
          )}
          name={'icon'}
          control={control}
        />
        {fileUrl && (
          <img
            src={fileUrl}
            alt={'icon'}
            className={'w-20 h-20 object-contain'}
          />
        )}
      </div>
      <div className={'flex'}>
        <Button onClick={handleSubmit(onSubmit)}>
          {t('form:buttons.save')}
        </Button>
      </div>
    </div>
  )
}
