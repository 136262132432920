import { knwAPI } from '@/utils/api/knw'
import {
  AddressInterface,
  GetCompanyAddressesResponseInterface,
} from '@/features/companies/redux/types'
import { setArrayCache } from '@/utils/api/rtkHelper'

const companyRepository = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAddresses: builder.query<AddressInterface[], number | string>({
      query: (id) => `/api/admin/companies/${id}/addresses`,
      transformResponse: (response: GetCompanyAddressesResponseInterface) =>
        response.data,
      providesTags: (result, _, id) =>
        setArrayCache(`CompanyAddress_${id}`, result),
    }),
  }),
})

export const { useGetAddressesQuery } = companyRepository
