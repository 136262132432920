import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { isObject } from 'lodash'
import { hasProperty } from '@vitest/expect'
import { ErrorResponseInterface, PaginatedResponseInterface } from '@/app/types'
import { TagTypes } from '@/utils/api/knw'

export const setPaginatedCache = <T extends { id: number | string } & object>(
  tag: TagTypes,
  data: PaginatedResponseInterface<T> | undefined
) => {
  return data
    ? [
        ...data.data.map(({ id }) => ({ type: tag, id } as const)),
        { type: tag, id: 'LIST' },
      ]
    : [{ type: tag, id: 'LIST' as const }]
}

export const setArrayCache = <T extends { id: number | string } & object>(
  tag: TagTypes,
  data: T[] | undefined
) => {
  return data
    ? [
        ...data.map(({ id }) => ({ type: tag, id } as const)),
        { type: tag, id: 'LIST' },
      ]
    : [{ type: tag, id: 'LIST' as const }]
}

export const setSingleObjectCache = <
  T extends { id: number | string } & object
>(
  tag: TagTypes,
  data: T | undefined
) => {
  return [{ type: tag, id: data?.id }]
}

export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return (
    isObject(error) &&
    hasProperty(error, 'status') &&
    hasProperty(error, 'data')
  )
}

export const isFetchBaseQueryErrorWithCode = (
  error: unknown
): error is FetchBaseQueryError & { data: ErrorResponseInterface } => {
  return (
    isFetchBaseQueryError(error) &&
    !!error.data &&
    hasProperty(error.data, 'code')
  )
}
