import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { VendingMachineInterface } from '../../types'
import { Carbon } from '@/utils/carbon'

const BasicInfoTable: FC<{
  device: VendingMachineInterface
}> = ({ device: data }) => {
  const { t } = useTranslation(['vending_machines', 'form', 'utils'])

  return (
    <Table hideHeader shadow={'sm'}>
      <TableHeader>
        <TableColumn>{t('vending_machines:details.columns.id')}</TableColumn>
        <TableColumn>{data.id}</TableColumn>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>{t('form:labels.id')}</TableCell>
          <TableCell>{data.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.device_name')}</TableCell>
          <TableCell>{data.device_name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.device_code')}</TableCell>
          <TableCell>{data.device_code}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.additional_info')}</TableCell>
          <TableCell>{data.additional_info}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.created_at')}</TableCell>
          <TableCell>
            {new Carbon(data.created_at).format('dd.MM.yyyy')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.synced_at')}</TableCell>
          <TableCell>{new Carbon(data.synced_at).toHumanDate()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.internal_status')}</TableCell>
          <TableCell>
            {t(`vending_machines:internal_status.${data.internal_status}`)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.is_online')}</TableCell>
          <TableCell>
            {data.is_online ? t('utils:yes') : t('utils:no')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('form:labels.is_running')}</TableCell>
          <TableCell>
            {data.is_running ? t('utils:yes') : t('utils:no')}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export { BasicInfoTable }
