'use client'

import { cn, DateRangePicker as BaseDateRangePicker } from '@nextui-org/react'
import { RangeValue } from '@react-types/shared'
import { DateValue } from '@react-types/datepicker'
import _ from 'lodash'
import { forwardRef } from 'react'
import { CustomDateRangePickerProps } from './DateRangePicker.types'
import { Carbon } from '@/utils/carbon'

const DateRangePicker = forwardRef<
  HTMLInputElement,
  CustomDateRangePickerProps
>((props, ref) => {
  const { classNames, isInvalid, label, onChange, value, ...rest } = props

  const handleChange = (newValue: RangeValue<DateValue>) => {
    const apiFormat = Carbon.dateRangeToAPIFormat(newValue)

    console.log(apiFormat)

    if (!apiFormat) return

    onChange?.(apiFormat)
  }

  return (
    <BaseDateRangePicker
      ref={ref}
      label={label}
      labelPlacement={'outside'}
      value={Carbon.datesToDatePicker(value)}
      onChange={handleChange}
      classNames={{
        label: cn('text-sm font-bold text-neutral-900', classNames?.label),
        inputWrapper: cn(
          'border border-opaque bg-white text-sm shadow-none',
          'focus-within:hover:bg-whiteŚ hover:bg-white group-data-[focus=true]:bg-white group-data-[hover=true]:bg-white',
          {
            'border-danger text-danger': isInvalid,
          },
          classNames?.inputWrapper
        ),
        input: cn(
          'text-sm leading-5 placeholder-content-tertiary',
          classNames?.input
        ),
        ..._.omit(classNames, ['inputWrapper', 'input', 'label']),
      }}
      size={'lg'}
      radius={'sm'}
      isInvalid={isInvalid}
      {...rest}
    />
  )
})

DateRangePicker.displayName = 'DateRangePicker'

export { DateRangePicker }
