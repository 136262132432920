import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Form } from '../../components'
import { SaveIntegrationRequestInterface } from '@/features/integrations/redux/types'
import {
  useGetIntegrationQuery,
  useUpdateIntegrationMutation,
} from '@/features/integrations/redux/integrationAPI'
import { Card } from '@/components'

const IntegrationsEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(['integrations'])
  const { id } = useParams() as { id: string }
  const [updateIntegration] = useUpdateIntegrationMutation()
  const navigate = useNavigate()
  const { data } = useGetIntegrationQuery(id)
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (data: SaveIntegrationRequestInterface) => {
    try {
      await updateIntegration({ body: data, id })
      enqueueSnackbar(t('integrations:edit.success'), { variant: 'success' })
      navigate('/integrations')
    } catch (error) {
      enqueueSnackbar(t('utils:errors.something_went_wrong'), {
        variant: 'error',
      })
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('integrations:edit.title')}
      </span>
      <Card>
        <Form data={data} onSubmit={onSubmit} />
      </Card>
    </div>
  )
}

export { IntegrationsEditScreen }
