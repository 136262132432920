import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UpdateCategoryPropsInterface } from '../../../../redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation'

class UpdateCategoryValidation
  implements ValidationInterface<UpdateCategoryPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<UpdateCategoryPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      icon: yup.mixed<File>().nullable(),
      color: yup
        .string()
        .nullable()
        .matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, t('validation:color')),
    })
  }

  defaultValues(): UpdateCategoryPropsInterface {
    return {
      name: '',
      icon: null,
      color: '',
    }
  }
}

export { UpdateCategoryValidation }
