import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from '@/app/hooks'
import { getIsAuthenticated } from '@/features/auth/redux/authSlice'
import { AuthLayout } from '@/components'

const AuthRoutes = () => {
  const isAuthorized = useAppSelector(getIsAuthenticated)

  return isAuthorized ? (
    <Navigate to={'/'} />
  ) : (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  )
}

export { AuthRoutes }
