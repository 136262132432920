import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import Lightbox from 'yet-another-react-lightbox'
import { VendingMachineProductInterface } from '../../types'

const ProductsTable: FC<{
  products: VendingMachineProductInterface[]
}> = ({ products }): ReactNode => {
  const { t } = useTranslation(['form', 'utils', 'vending_machines'])
  const [activeIndex, setActiveIndex] = useState<number>()

  return (
    <div className={'flex flex-col gap-4'}>
      <span className={'text-lg font-medium'}>
        {t('vending_machines:details.products.title')}
      </span>
      <Table>
        <TableHeader>
          <TableColumn>{t('form:labels.id')}</TableColumn>
          <TableColumn>{t('form:labels.image')}</TableColumn>
          <TableColumn>{t('form:labels.name')}</TableColumn>
          <TableColumn>{t('form:labels.total_stock')}</TableColumn>
          <TableColumn>{t('form:labels.external_id')}</TableColumn>
          <TableColumn>{t('form:labels.custom_code')}</TableColumn>
        </TableHeader>
        <TableBody>
          {products.map((product, index) => (
            <TableRow key={product.id}>
              <TableCell>{product.id}</TableCell>
              <TableCell>
                {product.thumbnail_url ? (
                  <img
                    onClick={() => setActiveIndex(index)}
                    src={product.thumbnail_url}
                    className={'w-10 h-10 cursor-pointer'}
                    alt={'thumbnail'}
                  />
                ) : null}
              </TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.total_stock}</TableCell>
              <TableCell>{product.external_id}</TableCell>
              <TableCell>{product.custom_code}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Lightbox
        open={activeIndex !== undefined}
        close={() => setActiveIndex(undefined)}
        slides={products
          .filter((p) => p.image_url)
          .map((product) => ({
            src: product.image_url as string,
            type: 'image',
          }))}
      />
    </div>
  )
}

export { ProductsTable }
