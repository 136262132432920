import { Fragment, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { ResetPasswordValidation } from '../validations/ResetPasswordValidation'
import { ResetPasswordPropsInterface } from '@/features/auth/redux/types'
import { useAppDispatch } from '@/app/hooks'
import { resetPasswordMutation } from '@/features/auth/redux/authAPI'
import { Button, FormControl, TextInput } from '@/components'
import { useValidation } from '@/utils/hooks/useValidation'

const ResetPasswordScreen = (): ReactNode => {
  const { t } = useTranslation(['auth', 'validation', 'form'])
  const [params] = useSearchParams()
  const { schema, defaultValues } = useValidation(
    new ResetPasswordValidation(),
    t
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (errors.token) {
      enqueueSnackbar(t('auth:reset_password.error'), {
        variant: 'error',
      })
    }
  }, [errors])

  useEffect(() => {
    if (params.get('token')) {
      setValue('token', params.get('token')!)
    }

    if (params.get('email')) {
      setValue('email', params.get('email')!)
    }
  }, [params, setValue])

  const onSubmit = (data: ResetPasswordPropsInterface) => {
    dispatch(resetPasswordMutation(data))
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('auth:reset_password.success'), {
          variant: 'success',
        })
        navigate('/login')
      })
      .catch(() =>
        enqueueSnackbar(t('auth:reset_password.error'), {
          variant: 'error',
        })
      )
  }

  return (
    <Fragment>
      <div className={'flex flex-col gap-y-6'}>
        <Controller
          name={'email'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl name={'email'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                label={t('form:labels.email')}
                type={'email'}
                placeholder={t('form:placeholders.email')}
                {...field}
              />
            </FormControl>
          )}
        />
        <Controller
          name={'password'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl name={'password'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                label={t('form:labels.password')}
                type={'password'}
                placeholder={t('form:placeholders.password')}
                {...field}
              />
            </FormControl>
          )}
        />
        <Controller
          name={'password_confirmation'}
          control={control}
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'password_confirmation'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                label={t('form:labels.password_confirmation')}
                type={'password'}
                placeholder={t('form:placeholders.password_confirmation')}
                {...field}
              />
            </FormControl>
          )}
        />
        <Button onClick={handleSubmit(onSubmit)}>
          {t('auth:reset_password.button')}
        </Button>
      </div>
    </Fragment>
  )
}

export { ResetPasswordScreen }
