import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { type RootState } from '@/app/store'

export const url = import.meta.env.VITE_KNW_API_URL

export const tagTypes = [
  'User',
  'Category',
  'Role',
  'Category',
  'Integration',
  'Post',
  'AgreementGroup',
]

export type TagTypes = (typeof tagTypes)[number]

const knwAPI = createApi({
  tagTypes,
  reducerPath: 'knwAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const currentLanguage = window.localStorage.getItem('i18nextLng')
      const token = (getState() as RootState).auth.token

      headers.set('Accept', 'application/json')
      headers.set('Content-Language', currentLanguage ?? 'pl')

      if (headers.get('Content-Type') === 'multipart/form-data') {
        headers.delete('Content-Type')
      } else {
        headers.set('Content-Type', 'application/json')
      }

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
})

export { knwAPI }
