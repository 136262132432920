import { Controller, useFormContext } from 'react-hook-form'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useDeleteImageInPostMutation } from '@/features/posts/redux/postAPI'
import {
  PostInterface,
  UpdatePostRequestInterface,
} from '@/features/posts/redux/types'
import { Card, FormControl } from '@/components'

type Props = {
  data?: PostInterface
}

type ImageArray = {
  url: string
  id?: number | string
}

export const ImageForm: FC<Props> = ({ data }): ReactNode => {
  const { t } = useTranslation(['posts', 'form', 'validation', 'utils'])
  const [deleteImage] = useDeleteImageInPostMutation()
  const [images, setImages] = useState<ImageArray[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const { setValue, control, watch } =
    useFormContext<UpdatePostRequestInterface>()
  const watchImages = watch('images')

  useEffect(() => {
    if (data && data.images) {
      setImages([])
      _.forEach(data.images, (image) => {
        setImages((prev) => [
          ...prev,
          { url: image.preview_url, id: image.uuid },
        ])
      })
    }
  }, [data])

  const handleDeleteImage = async (index: number) => {
    if (!data) return

    const image = images[index]

    if (image.id) {
      try {
        await deleteImage({
          postId: data.id,
          id: image.id,
        }).unwrap()
        enqueueSnackbar(t('posts:edit.images.delete_success'), {
          variant: 'success',
        })
      } catch (e) {
        enqueueSnackbar(t('utils:errors.something_went_wrong'), {
          variant: 'error',
        })
      }
    } else {
      const newImages = watchImages?.filter((_, i) => i !== index)
      setValue('images', newImages)
    }

    const newImages = images.filter((_, i) => i !== index)
    setImages(newImages)
  }

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files) {
      const images = Array.from(files).map((file) => ({
        url: URL.createObjectURL(file),
        exists: false,
      }))

      setImages((prev) => [...prev, ...images])

      setValue(
        'images',
        _.map(files, (file) => file)
      )
    }

    e.target.value = ''
  }

  return (
    <Card className={'flex flex-col gap-6'}>
      <div className={'flex justify-between'}>
        <span className={'font-medium'}>{t('posts:edit.images.title')}</span>
        <Controller
          render={({ formState: { errors } }) => (
            <FormControl name={'images'} errors={errors}>
              <input
                type={'file'}
                accept={'image/*'}
                max={1}
                multiple={true}
                onChange={handleUploadImage}
              />
            </FormControl>
          )}
          name={'images'}
          control={control}
        />
      </div>
      <div className={'grid grid-cols-2 2xl:grid-cols-8'}>
        {images.map((image, index) => (
          <div className={'max-w-32 relative'} key={index}>
            <img src={image.url} alt={''} />
            <button
              className={
                'absolute -top-2 -right-2 size-5 rounded-full bg-red-600 flex justify-center items-center'
              }
              onClick={() => handleDeleteImage(index)}
            >
              <FontAwesomeIcon icon={faTimes} className={'text-white'} />
            </button>
          </div>
        ))}
      </div>
    </Card>
  )
}
