import { TFunction } from 'i18next'
import * as yup from 'yup'
import { SaveProductPropsInterface } from '@/features/products/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class SaveProductValidation
  implements ValidationInterface<SaveProductPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<SaveProductPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      category_id: yup
        .number()
        .typeError(t('validation:required'))
        .required(t('validation:required'))
        .notOneOf([0], t('validation:required')),
    })
  }

  defaultValues(): SaveProductPropsInterface {
    return {
      name: '',
      category_id: 0,
    }
  }
}

export { SaveProductValidation }
