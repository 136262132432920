import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  useGetProductQuery,
  useUpdateProductMutation,
} from '@/features/products/redux/productAPI'
import { SaveProductPropsInterface } from '@/features/products/redux/types'
import { Card } from '@/components'
import { Form } from '@/features/products/resources/components'

export const ProductEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(['products'])
  const { id } = useParams<{ id: string }>() as { id: string }
  const [updateProduct] = useUpdateProductMutation()
  const { data, isLoading } = useGetProductQuery(id)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (data: SaveProductPropsInterface) => {
    try {
      await updateProduct({ id: id, body: data }).unwrap()
      enqueueSnackbar(t('products:edit.success'), { variant: 'success' })
      navigate('/products')
    } catch (error) {
      // empty block
    }
  }

  if (isLoading) {
    return (
      <div className={'p-4'}>
        <div
          className={'w-full h-36 bg-neutral-300 animate-pulse rounded-medium'}
        />
      </div>
    )
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('categories:edit.title')}
      </span>
      <Card>
        <Form data={data} onSubmit={onSubmit} />
      </Card>
    </div>
  )
}
