import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { NextUIProvider } from '@nextui-org/react'
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from 'notistack'
import { persistor, store } from './app/store'
import App from './App'
import './index.css'
import 'yet-another-react-lightbox/styles.css'
import './languages/i18n'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <NextUIProvider locale={'pl-PL'}>
        <SnackbarProvider
          autoHideDuration={3000}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </SnackbarProvider>
      </NextUIProvider>
    </Suspense>
  </React.StrictMode>
)
