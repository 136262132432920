import { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { SelectItem } from '@nextui-org/react'
import { parseDate } from '@internationalized/date'
import { associateValues } from './helper'
import { Carbon } from '@/utils/carbon'
import { UnitEnum } from '@/app/enums/unitEnum'
import {
  PostInterface,
  UpdatePostRequestInterface,
} from '@/features/posts/redux/types'
import {
  Card,
  Checkbox,
  DatePicker,
  DateRangePicker,
  FormControl,
  Label,
  SearchCategory,
  SearchProduct,
  Selectv2,
  Textarea,
  TextInput,
} from '@/components'

type Props = {
  data?: PostInterface
}

const BasicDataForm: FC<Props> = ({ data }): ReactNode => {
  const { t } = useTranslation(['posts'])
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<UpdatePostRequestInterface>()

  useEffect(() => {
    if (data && data.category && data.product) {
      associateValues(data, setValue)
    }
  }, [data, setValue])

  if (!data) {
    return <span></span>
  }

  return (
    <Card>
      <span className={'font-medium'}>{t('posts:edit.basic.title')}</span>
      <div className={'flex flex-col gap-y-4 pt-6'}>
        <div className={'grid lg:grid-cols-3 gap-4'}>
          <div className={'flex flex-col'}>
            <Label label={t('form:labels.created_at')} />
            <span>
              {new Carbon().parse(data.created_at).format('dd.MM.yyyy HH:mm')}
            </span>
          </div>
          <div className={'flex flex-col'}>
            <Label label={t('form:labels.expired_at')} />
            <span>
              {data.expired_at
                ? new Carbon().parse(data.expired_at).format('dd.MM.yyyy HH:mm')
                : '-'}
            </span>
          </div>
          <div className={'flex flex-col gap-y-1'}>
            <Controller
              render={({ field, formState: { errors } }) => (
                <FormControl name={'is_prow_wps'} errors={errors}>
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t('form:labels.is_prow_wps')}
                  />
                </FormControl>
              )}
              name={'is_prow_wps'}
              control={control}
            />
          </div>
          <div className={'flex flex-col gap-y-3'}>
            <div className={'flex flex-col'}>
              <Label label={t('form:labels.status')} />
              <span>{t(`posts:statuses.${data.status}`)}</span>
            </div>
          </div>
        </div>
        <div className={'grid lg:grid-cols-2 2xl:grid-cols-3 gap-4'}>
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'price'} errors={errors}>
                <TextInput
                  type={'number'}
                  label={t('form:labels.price')}
                  isInvalid={!!error}
                  step={0.01}
                  value={field.value.toString() ?? ''}
                  onChange={field.onChange}
                />
              </FormControl>
            )}
            name={'price'}
            control={control}
          />
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'unit'} errors={errors}>
                <Selectv2
                  errorMessage={error?.message}
                  isRequired
                  selectedKeys={[field.value]}
                  onChange={field.onChange}
                  label={t('form:labels.unit')}
                  placeholder={t('form:labels.select_unit')}
                >
                  {_.map(UnitEnum, (unit) => (
                    <SelectItem key={unit} value={unit}>
                      {t(`utils:units.${unit}`)}
                    </SelectItem>
                  ))}
                </Selectv2>
              </FormControl>
            )}
            name={'unit'}
            control={control}
          />
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'quantity'} errors={errors}>
                <TextInput
                  isInvalid={!!error}
                  type={'number'}
                  label={t('form:labels.quantity')}
                  step={0.01}
                  value={field.value.toString() ?? ''}
                  onChange={field.onChange}
                />
              </FormControl>
            )}
            name={'quantity'}
            control={control}
          />
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'production_place'} errors={errors}>
                <TextInput
                  isInvalid={!!error}
                  label={t('form:labels.production_place')}
                  {...field}
                  value={field.value ?? ''}
                />
              </FormControl>
            )}
            name={'production_place'}
            control={control}
          />
          <Controller
            render={({ field, formState: { errors } }) => (
              <FormControl name={'harvest_date'} errors={errors}>
                <DateRangePicker
                  label={t('form:labels.harvest_date')}
                  value={field.value as [string, string]}
                  onChange={field.onChange}
                />
              </FormControl>
            )}
            name={'harvest_date'}
            control={control}
          />
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'expired_at'} errors={errors}>
                <DatePicker
                  isInvalid={!!error}
                  label={t('form:labels.expired_at')}
                  value={field.value ? parseDate(field.value) : null}
                  onChange={(value) => field.onChange(value.toString())}
                />
              </FormControl>
            )}
            name={'expired_at'}
            control={control}
          />
          <div className={'flex flex-col gap-y-2 lg:col-span-2 2xl:col-span-3'}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl name={field.name} errors={errors}>
                  <SearchCategory
                    initialInputValue={data?.category?.name}
                    isInvalid={!!error}
                    onSelectionChange={field.onChange}
                    selectedKey={field.value}
                    label={t('form:labels.category')}
                    placeholder={t('form:placeholders.category')}
                  />
                </FormControl>
              )}
              name={'category_id'}
              control={control}
            />
          </div>
          <div className={'col-span-2 2xl:col-span-3 flex flex-col'}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <SearchProduct
                  initialInputValue={data?.product?.name}
                  selectedKey={field.value}
                  onSelectionChange={field.onChange}
                  isInvalid={!!error}
                  label={t('form:labels.product')}
                  placeholder={t('form:placeholders.product')}
                />
              )}
              name={'product_id'}
              control={control}
            />
          </div>
        </div>
        <div className={'flex flex-col gap-y-4'}>
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'title'} errors={errors}>
                <TextInput
                  isInvalid={!!error}
                  label={t('form:labels.title')}
                  {...field}
                />
              </FormControl>
            )}
            name={'title'}
            control={control}
          />
          <Controller
            render={({
              field,
              formState: { errors },
              fieldState: { error },
            }) => (
              <FormControl name={'description'} errors={errors}>
                <Textarea
                  isInvalid={!!error}
                  label={t('form:labels.description')}
                  minRows={5}
                  {...field}
                />
              </FormControl>
            )}
            name={'description'}
            control={control}
          />
        </div>
      </div>
    </Card>
  )
}

export { BasicDataForm }
