import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  faList,
  faPenToSquare,
  faRightFromBracket,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { SidebarToggle } from './SidebarToggle'
import { ChildrenSidebar } from './ChildrenSidebar'
import {
  ChildSidebarMenuItemInterface,
  SidebarMenuItemInterface,
} from '@/utils/routes/privateRoutes/types'
import { ClickAwayListener } from '@/components'
import { useLogoutMutation } from '@/features/auth/redux/authAPI'
import { useAppDispatch } from '@/app/hooks'

const Sidebar = (): ReactNode => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [currentChildren, setCurrentChildren] = useState<{
    children: ChildSidebarMenuItemInterface[]
    title: string
  }>({ children: [], title: '' })
  const navigate = useNavigate()
  const [logout] = useLogoutMutation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleOpenChildren = (index: string) => {
    const item = items.find((item) => item.index === index)

    if (item && item.children.length > 0) {
      setCurrentChildren({
        children: item.children,
        title: item.title,
      })
      setOpenSidebar(true)
    } else {
      setOpenSidebar(false)
    }
  }

  const handleLogout = async () => {
    try {
      await logout()
      dispatch({ type: 'auth/logout' })

      navigate('/login')
    } catch (error) {
      enqueueSnackbar(t('utils:alerts.errors.500'), {
        variant: 'error',
      })

      dispatch({ type: 'auth/logout' })
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenSidebar(false)}>
      <div>
        <div
          className={
            'fixed bottom-0 z-[50] flex w-auto flex-col-reverse bg-white lg:top-0 lg:flex-row'
          }
        >
          <div
            className={
              'flex h-[80px] w-screen items-center border-r border-neutral-600 bg-white px-4 pb-8 pt-4 lg:h-full lg:w-[80px] lg:items-baseline'
            }
          >
            <ul className={'flex items-center gap-4 py-4 lg:flex-col h-full'}>
              {items.map((item) => (
                <SidebarToggle
                  {...item}
                  key={item.index}
                  onClick={handleOpenChildren}
                />
              ))}
              <div className={'flex-1'} />
              <SidebarToggle
                index={'profile'}
                title={'profile'}
                icon={faUser}
                matchUrls={['/profile']}
                onClick={() => navigate('/profile')}
              />
              <SidebarToggle
                index={'logout'}
                title={'logout'}
                icon={faRightFromBracket}
                matchUrls={['/logout']}
                onClick={handleLogout}
              />
            </ul>
          </div>
        </div>
        <ChildrenSidebar
          items={currentChildren.children}
          title={currentChildren.title}
          open={openSidebar}
          closeSidebar={() => setOpenSidebar(false)}
        />
      </div>
    </ClickAwayListener>
  )
}

const items: SidebarMenuItemInterface[] = [
  {
    title: 'layout:sidebar.menu.users',
    index: 'users',
    matchUrls: ['/users', '/users/create', '/roles', '/roles/create'],
    icon: faUsers,
    children: [
      {
        title: 'layout:sidebar.menu.users',
        path: '/users',
      },
      {
        title: 'layout:sidebar.menu.roles',
        path: '/roles',
      },
    ],
  },
  {
    title: 'layout:sidebar.menu.posts',
    index: 'posts',
    matchUrls: [
      '/categories',
      '/categories/create',
      '/products',
      '/products/create',
      '/posts',
    ],
    icon: faList,
    children: [
      {
        title: 'layout:sidebar.menu.categories',
        path: '/categories',
      },
      {
        title: 'layout:sidebar.menu.posts',
        path: '/posts',
      },
      {
        title: 'layout:sidebar.menu.products',
        path: '/products',
      },
    ],
  },
  {
    title: 'layout:sidebar.menu.content_management',
    index: 'content_management',
    matchUrls: [
      '/agreements',
      '/agreements/create',
      '/integrations',
      '/integrations/create',
      '/vending-machines',
      '/events',
    ],
    icon: faPenToSquare,
    children: [
      {
        title: 'layout:sidebar.menu.agreements',
        path: '/agreements',
      },
      {
        title: 'layout:sidebar.menu.integrations',
        path: '/integrations',
      },
      {
        title: 'layout:sidebar.menu.vending_machines',
        path: '/vending-machines',
      },
      {
        title: 'layout:sidebar.menu.events',
        path: '/events',
      },
    ],
  },
]

export { Sidebar }
