import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '@nextui-org/react'
import { Table } from './partials'

const VendingMachines = (): ReactNode => {
  const { t } = useTranslation('vending_machines')

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <Card>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>{t('list.title')}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}

export { VendingMachines }
