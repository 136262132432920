import { knwAPI } from '@/utils/api/knw'
import {
  GetProductResponseInterface,
  GetProductsResponseInterface,
  SaveProductPropsInterface,
  SearchProductsResponseInterface,
} from '@/features/products/redux/types'
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useStoreProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useLazySearchProductQuery,
  useSearchProductQuery,
} = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<GetProductsResponseInterface['data'], string>({
      query: (query) => `/api/admin/products?${query}`,
      transformResponse: (response: GetProductsResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache('Product', result),
    }),
    searchProduct: builder.query<
      SearchProductsResponseInterface['data'],
      string
    >({
      query: (query) => `/api/admin/products/search?${query}`,
      transformResponse: (response: SearchProductsResponseInterface) =>
        response.data,
      providesTags: (result) => setArrayCache('Product', result),
    }),
    getProduct: builder.query<
      GetProductResponseInterface['data'],
      string | number
    >({
      query: (id) => `/api/admin/products/${id}`,
      transformResponse: (response: GetProductResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache('Product', result),
    }),
    storeProduct: builder.mutation<void, SaveProductPropsInterface>({
      query: (body) => ({
        url: '/api/admin/products',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Product', id: 'LIST' }],
    }),
    updateProduct: builder.mutation<
      void,
      { id: number | string; body: SaveProductPropsInterface }
    >({
      query: ({ id, body }) => ({
        url: `/api/admin/products/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'Product', id: 'LIST' },
        { type: 'Product', id: id },
      ],
    }),
    deleteProduct: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `/api/admin/products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Product', id: 'LIST' }],
    }),
  }),
})
