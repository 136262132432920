import {
  Autocomplete as Component,
  AutocompleteProps,
} from '@nextui-org/autocomplete'
import { omit } from 'lodash'
import { cn } from '@/utils/helpers/cn'

const Autocomplete = <T extends object>(props: AutocompleteProps<T>) => {
  const { classNames, listboxProps, inputProps, children, ...rest } = props

  return (
    <Component
      labelPlacement={'outside'}
      classNames={{
        listboxWrapper: cn(
          'bg-white font-work-sans text-sm leading-[20px] shadow-none data-[hover=true]:bg-white',
          classNames?.listboxWrapper
        ),
        listbox: cn('bg-white', classNames?.listbox),
        ...omit(classNames, 'listboxWrapper', 'listbox'),
      }}
      radius={'sm'}
      popoverProps={{
        radius: 'sm',
      }}
      listboxProps={{
        itemClasses: {
          base: cn(
            [
              'group-data-[focus-visible=true]:ring-offset-background-white data-[selectable=true]:hover:bg-foundation-primary-a data-[selectable=true]:hover:text-white',
              'data-[selectable=true]:focus:bg-foundation-primary-a data-[selectable=true]:focus:text-white',
              'data-[hover=true]:bg-foundation-primary-a data-[hover=true]:text-white',
            ],
            listboxProps?.itemClasses?.base
          ),
        },
        ...listboxProps,
      }}
      inputProps={{
        classNames: {
          label: cn(
            'font-manrope text-sm font-bold text-neutral-900',
            inputProps?.classNames?.label
          ),
          inputWrapper: cn(
            [
              'border border-opaque bg-white font-work-sans text-xs leading-[20px] shadow-none',
              'data-[hover=true]:bg-white group-data-[focus=true]:bg-white',
            ],
            {
              'border-foundation-danger text-foundation-danger':
                props.isInvalid,
            },
            inputProps?.classNames?.inputWrapper
          ),
          input: cn('font-work-sans text-sm leading-[20px]'),
          ...omit(inputProps?.classNames, ['inputWrapper', 'label']),
        },
        ...inputProps,
      }}
      size={'lg'}
      {...rest}
    >
      {children}
    </Component>
  )
}

export { Autocomplete }
