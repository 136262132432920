import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'
import { LogInPropsInterface } from '@/features/auth/redux/types'

class LoginValidation implements ValidationInterface<LogInPropsInterface> {
  rules(t: TFunction): yup.ObjectSchema<LogInPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      email: yup
        .string()
        .email(t('validation:email_invalid'))
        .required(t('validation:required')),
      password: yup
        .string()
        .min(8, t('validation:min_length', { length: 8 }))
        .required(t('validation:required')),
    })
  }

  defaultValues(): LogInPropsInterface {
    return {
      email: '',
      password: '',
    }
  }
}

export { LoginValidation }
