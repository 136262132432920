import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'

export type Action = {
  icon: IconDefinition
  onClick: () => void
  permission?: PermissionEnum
  buttonClassName?: string
  condition?: boolean
}

export type ActionStackProps = {
  actions: Action[]
}

export type BaseActionStackActionType = {
  action: () => void
  permission: PermissionEnum
}

export const baseActionStack = ({
  onDelete,
  onEdit,
  onDetails,
}: {
  onDelete?: BaseActionStackActionType
  onEdit?: BaseActionStackActionType
  onDetails?: BaseActionStackActionType
}): Action[] => [
  ...(onDelete
    ? [
        {
          icon: faTrash,
          permission: onDelete.permission,
          onClick: onDelete.action,
          buttonClassName: 'bg-red-500',
        },
      ]
    : []),
  ...(onEdit
    ? [
        {
          icon: faEdit,
          permission: onEdit.permission,
          onClick: onEdit.action,
          buttonClassName: 'bg-primary-600',
        },
      ]
    : []),
  ...(onDetails
    ? [
        {
          icon: faEye,
          permission: onDetails.permission,
          onClick: onDetails.action,
          buttonClassName: 'bg-yellow-500',
        },
      ]
    : []),
]
