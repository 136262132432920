import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'
import { UnitEnum } from '@/app/enums/unitEnum'
import {
  PostStatusEnum,
  UpdatePostRequestInterface,
} from '@/features/posts/redux/types'
import { Carbon } from '@/utils/carbon'

class UpdatePostValidation
  implements ValidationInterface<UpdatePostRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<UpdatePostRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      product_id: yup.number().required(t('validation:required')),
      category_id: yup.number().required(t('validation:required')),
      address_id: yup.number().required(t('validation:required')),
      harvest_available_all_time: yup
        .boolean()
        .required(t('validation:required')),
      production_place_postal_code: yup.string().nullable(),
      status: yup.mixed<PostStatusEnum>().required(t('validation:required')),
      price: yup
        .number()
        .required(t('validation:required'))
        .min(0.01, t('validation:min', { min: 0.01 })),
      description: yup
        .string()
        .required(t('validation:required'))
        .min(20, t('validation:field_min_length', { length: 20 })),
      unit: yup.mixed<UnitEnum>().required(t('validation:required')),
      title: yup.string().required(t('validation:required')),
      quantity: yup
        .number()
        .required(t('validation:required'))
        .min(1, t('validation:min', { min: 1 })),
      production_place: yup.string().required(t('validation:required')),
      harvest_date: yup
        .array()
        .notRequired()
        .required(t('validation.required'))
        .test(
          'date-range',
          t('validation.first_date_before_second'),
          function (value) {
            const [startDateTime, endDateTime] = value.map(
              (date) => new Carbon(date)
            )

            return startDateTime.toDate() < endDateTime.toDate()
          }
        ),
      is_prow_wps: yup.boolean().required(t('validation:required')),
      expired_at: yup.string().nullable(),
      images: yup
        .array()
        .of(yup.mixed<File>().required())
        .max(1, t('validation:max_images', { max: 1 }))
        .nullable(),
    })
  }

  defaultValues(): UpdatePostRequestInterface {
    return {
      product_id: 0,
      category_id: 0,
      title: '',
      description: '',
      price: 0,
      unit: UnitEnum.KILOGRAM,
      quantity: 0,
      production_place: '',
      harvest_date: [
        new Carbon().toDatePickerValue(),
        new Carbon().toDatePickerValue(),
      ],
      is_prow_wps: false,
      status: 'draft',
      address_id: 0,
      production_place_postal_code: '',
      harvest_available_all_time: false,
      expired_at: '',
    }
  }
}

export { UpdatePostValidation }
