import { knwAPI } from '@/utils/api/knw'
import {
  GetIntegrationResponseInterface,
  GetIntegrationsResponseInterface,
  SaveIntegrationRequestInterface,
} from '@/features/integrations/redux/types'
import { setArrayCache, setSingleObjectCache } from '@/utils/api/rtkHelper'

export const {
  useGetIntegrationsQuery,
  useGetIntegrationQuery,
  useStoreIntegrationMutation,
  useUpdateIntegrationMutation,
  useDeleteIntegrationMutation,
} = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrations: builder.query<
      GetIntegrationsResponseInterface['data'],
      void
    >({
      query: () => `/api/admin/integrations`,
      transformResponse: (response: GetIntegrationsResponseInterface) =>
        response.data,
      providesTags: (result) => setArrayCache('Integration', result),
    }),
    getIntegration: builder.query<
      GetIntegrationResponseInterface['data'],
      string | number
    >({
      query: (id) => `/api/admin/integrations/${id}`,
      transformResponse: (response: GetIntegrationResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache('Integration', result),
    }),
    storeIntegration: builder.mutation<void, SaveIntegrationRequestInterface>({
      query: (body) => ({
        url: `/api/admin/integrations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Integration', id: 'LIST' }],
    }),
    updateIntegration: builder.mutation<
      void,
      { id: string | number; body: SaveIntegrationRequestInterface }
    >({
      query: ({ id, body }) => ({
        url: `/api/admin/integrations/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: 'Integration', id },
        { type: 'Integration', id: 'LIST' },
      ],
    }),
    deleteIntegration: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `/api/admin/integrations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Integration', id: 'LIST' }],
    }),
  }),
})
