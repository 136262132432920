import { knwAPI } from '@/utils/api/knw'
import {
  setArrayCache,
  setPaginatedCache,
  setSingleObjectCache,
} from '@/utils/api/rtkHelper'
import {
  CategoryInterface,
  GetCategoriesResponseInterface,
  GetCategoryResponseInterface,
  GetSearchCategoriesResponseInterface,
} from '@/features/categories/redux/types'

export const {
  useGetCategoriesQuery,
  useDeleteCategoryMutation,
  useLazySearchCategoriesQuery,
  useStoreCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryQuery,
  useSearchCategoriesQuery,
} = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<
      GetCategoriesResponseInterface['data'],
      string
    >({
      query: (query) => `/api/admin/categories?${query}`,
      transformResponse: (response: GetCategoriesResponseInterface) =>
        response.data,
      providesTags: (result) => setPaginatedCache('Category', result),
      keepUnusedDataFor: 60 * 60,
    }),
    deleteCategory: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/api/admin/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Category', id: 'LIST' }],
    }),
    searchCategories: builder.query<CategoryInterface[], string>({
      query: (query) => `/api/admin/categories/search?${query}`,
      transformResponse: (response: GetSearchCategoriesResponseInterface) =>
        response.data,
      providesTags: (result) => setArrayCache('Category', result),
    }),
    storeCategory: builder.mutation<void, FormData>({
      query: (body) => ({
        url: '/api/admin/categories',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: [{ type: 'Category', id: 'LIST' }],
    }),
    getCategory: builder.query<CategoryInterface, number | string>({
      query: (id) => `/api/admin/categories/${id}`,
      transformResponse: (response: GetCategoryResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache('Category', result),
    }),
    updateCategory: builder.mutation<
      void,
      { id: string | number; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/api/admin/categories/${id}`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Category', id },
        { type: 'Category', id: 'LIST' },
      ],
    }),
  }),
})
