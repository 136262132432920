import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useDisclosure } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import { VendingMachineInterface } from '../../types'
import {
  useDeleteVendingMachineMutation,
  useGetVendingMachinesQuery,
} from '../../repositories'
import { EditModal } from '../EditModal'
import { usePagination } from '@/utils/hooks'
import { Query } from '@/utils/query'
import { ActionStack, baseActionStack, BaseTable } from '@/components'
import { PermissionEnum } from '@/features/permissions'
import { Nullable } from '@/utils/types'

const columnHelper = createColumnHelper<VendingMachineInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onDetails: (id: number) => void
) => [
  columnHelper.accessor('device_name', {
    id: 'name',
    header: t('form:labels.device_name'),
  }),
  columnHelper.accessor('device_id', {
    id: 'device_id',
    header: t('form:labels.device_id'),
  }),
  columnHelper.accessor('device_code', {
    id: 'device_code',
    header: t('form:labels.device_code'),
  }),
  columnHelper.accessor('is_online', {
    id: 'is_online',
    header: t('form:labels.is_online'),
    cell: ({
      row: {
        original: { is_online },
      },
    }) => (is_online ? t('utils:yes') : t('utils:no')),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDetails: {
            permission: PermissionEnum.VENDING_MACHINE_VIEW,
            action: () => onDetails(row.original.id),
          },
          onEdit: {
            permission: PermissionEnum.USER_SAVE,
            action: () => onEdit(row.original.id),
          },
          onDelete: {
            permission: PermissionEnum.USER_DELETE,
            action: () => onDelete(row.original.id),
          },
        })}
      />
    ),
  }),
]

const Table = (): ReactNode => {
  const { t } = useTranslation(['form', 'utils'])
  const [pagination, setPagination] = usePagination()
  const [data, setData] = useState<VendingMachineInterface[]>([])
  const navigate = useNavigate()
  const query = useMemo(
    () =>
      new Query().limit(pagination.pageSize).page(pagination.pageIndex).url(),
    [pagination.pageIndex, pagination.pageSize]
  )
  const { data: apiData } = useGetVendingMachinesQuery(query)
  const [deleteVendingMachine] = useDeleteVendingMachineMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentId, setCurrentId] = useState<Nullable<number>>(null)

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteVendingMachine(id)

      enqueueSnackbar(t('vending_machines:list.delete_success'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('utils:alerts.errors.500'))
    }
  }

  const handleEdit = (id: number) => {
    setCurrentId(id)
    onOpen()
  }

  const table = useReactTable({
    columns: columns(t, handleDelete, handleEdit, (id) =>
      navigate(`/vending-machines/${id}`)
    ),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <Fragment>
      <div className={'flex w-full'}>
        <BaseTable table={table} pagination={apiData?.pagination} />
      </div>
      {currentId && (
        <EditModal deviceId={currentId} isOpen={isOpen} onClose={onClose} />
      )}
    </Fragment>
  )
}

export { Table }
