import React, { useMemo } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SidebarMenuItemInterface } from '@/utils/routes/privateRoutes/types'
import { cn } from '@/utils/helpers/cn'

type Props = Omit<SidebarMenuItemInterface, 'children'> & {
  // eslint-disable-next-line no-unused-vars
  onClick: (index: string) => void
}

const SidebarToggle: React.FC<Props> = ({
  url,
  icon,
  index,
  onClick,
  redirect,
  matchUrls,
}): React.ReactNode => {
  const location = useLocation()
  const match = useMatch(location.pathname)
  const isActive = useMemo(
    () => matchUrls.includes(match?.pathname ?? ''),
    [match, matchUrls]
  )
  const navigate = useNavigate()

  const handleClick = () => {
    onClick(index)

    if (redirect && url) {
      navigate(url)
    }
  }

  const classes = cn('items-center justify-center flex w-12 h-12', {
    'bg-[#F3F9FE] flex rounded-lg': isActive,
    'text-gray-50': !isActive,
    'text-primary-600': isActive,
  })

  return (
    <button onClick={handleClick} className={classes}>
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

export { SidebarToggle }
