import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation/useValidation.types'

export interface SuccessResponseInterface {
  data: unknown
  code: number
}

export interface ErrorResponseInterface {
  code: ResponseCodeEnum
  data: unknown
}

export interface FormInterface<T extends object, D extends object> {
  onSubmit: (data: T) => void
  errors?: BackendValidationErrorInterface<T>
  data?: D
}

export interface PaginationInterface {
  count: number
  current_page: number
  per_page: number
  total: number
  total_pages: number
}

export interface Select2OptionInterface {
  label: string
  value: string
}

export interface PaginatedResponseInterface<T> {
  data: T[]
  pagination: PaginationInterface
}

export enum ResponseCodeEnum {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  EXCEPTION = 500,
  CATEGORY_ASSIGNED_TO_POST = 1000,
  PRODUCT_ASSIGNED_TO_POST = 1001,
  POST_ALREADY_RESERVED = 1002,
}

export interface NominatimSearchResponseInterface {
  place_id: number
  licence: string
  osm_type: string
  osm_id: number
  boundingbox: string[]
  lat: number
  lon: number
  display_name: string
  place_rank: number
  category: string
  type: string
  importance: number
  address: {
    town?: string
    village?: string
    city: string
    state_district: string
    state: string
    country: string
    country_code: string
  }
  label: string
}
