import { FieldValues } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FormControlTypes } from './FormControl.types'
import { ValidationMessages } from '@/components'
import { cn } from '@/utils/helpers/cn'

/**
 * Component that wraps form inputs and displays validation messages
 */
const FormControl = <T extends FieldValues>({
  errors,
  className,
  children,
  name,
}: FormControlTypes<T>) => {
  const classNames = cn(className, 'flex flex-col gap-2')

  return (
    <div className={classNames}>
      {children}
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ messages, message }) => (
          <ValidationMessages
            messages={messages ? messages : { message: message }}
          />
        )}
      />
    </div>
  )
}

export { FormControl }
