import { FC } from 'react'
import { MultipleFieldErrors } from 'react-hook-form'
import { InvalidMessage } from './InvalidMessage'

const ValidationMessages: FC<{
  messages: MultipleFieldErrors | undefined
}> = ({ messages }) => {
  if (!messages) return null

  return (
    <div className={'flex flex-col gap-1'}>
      {Object.entries(messages).map(([type, message]) => (
        <InvalidMessage message={message} key={type} />
      ))}
    </div>
  )
}

export { ValidationMessages }
