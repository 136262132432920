import { FC } from 'react'
import { ValidateResult } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'

const InvalidMessage: FC<{
  message: string | ValidateResult
}> = ({ message }) => {
  return (
    <div className={'flex items-center gap-1'}>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className={'text-foundation-danger'}
      />
      <span className={'text-foundation-danger text-xs'}>{message}</span>
    </div>
  )
}

export { InvalidMessage }
