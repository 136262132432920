import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDisclosure } from '@nextui-org/react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack'
import { match } from 'ts-pattern'
import { EventInterface } from '../../types'
import { useChangeStatusMutation, useGetEventsQuery } from '../../repositories'
import { EventStatusEnum } from '../../enums'
import { DetailsModal } from '../DetailsModal'
import { usePagination } from '@/utils/hooks'
import { Query } from '@/utils/query'
import { ActionStack, baseActionStack, BaseTable } from '@/components'
import { PermissionEnum } from '@/features/permissions'
import { Nullable } from '@/utils/types'

const columnHelper = createColumnHelper<EventInterface>()

const columns = (
  t: TFunction,
  onDetails: (id: number) => void,
  onAccept: (id: number) => void,
  onReject: (id: number) => void
) => [
  columnHelper.accessor('id', {
    id: 'id',
    header: t('form:labels.id'),
  }),
  columnHelper.accessor('title', {
    id: 'title',
    header: t('form:labels.title'),
    cell: ({ row }) => (
      <span className={'max-w-24 truncate'}>{row.original.title}</span>
    ),
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: t('form:labels.type'),
    cell: ({ row }) => t(`events:types.${row.original.type}`),
  }),
  columnHelper.accessor('creator.email', {
    id: 'email',
    header: t('form:labels.email'),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionStack
        actions={[
          ...baseActionStack({
            onDetails: {
              permission: PermissionEnum.VENDING_MACHINE_VIEW,
              action: () => onDetails(row.original.id),
            },
          }),
          {
            onClick: () => onAccept(row.original.id),
            icon: faCheck,
            buttonClassName: 'bg-green-500 text-white',
          },
          {
            onClick: () => onReject(row.original.id),
            icon: faTimes,
            buttonClassName: 'bg-red-500 text-white',
          },
        ]}
      />
    ),
  }),
]

const Table = (): ReactNode => {
  const { t } = useTranslation(['form', 'utils', 'events'])
  const [pagination, setPagination] = usePagination()
  const [data, setData] = useState<EventInterface[]>([])
  const navigate = useNavigate()
  const query = useMemo(
    () =>
      new Query()
        .limit(pagination.pageSize)
        .page(pagination.pageIndex)
        .where('status', EventStatusEnum.PENDING)
        .includes('creator')
        .url(),
    [pagination.pageIndex, pagination.pageSize]
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: apiData } = useGetEventsQuery(query)
  const [currentId, setCurrentId] = useState<Nullable<number>>(null)
  const [updateStatus] = useChangeStatusMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeStatus = async (status: EventStatusEnum, id: number) => {
    try {
      await updateStatus({ id, status }).unwrap()

      match(status)
        .with(EventStatusEnum.APPROVED, () =>
          enqueueSnackbar(t('events:list.notifications.accepted'), {
            variant: 'success',
          })
        )
        .with(EventStatusEnum.REJECTED, () =>
          enqueueSnackbar(t('events:list.notifications.rejected'), {
            variant: 'error',
          })
        )
    } catch (error) {
      enqueueSnackbar(t('utils:alerts.errors.500'), {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(
      t,
      (id) => {
        setCurrentId(id)
        onOpen()
      },
      (id) => handleChangeStatus(EventStatusEnum.APPROVED, id),
      (id) => handleChangeStatus(EventStatusEnum.REJECTED, id)
    ),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <Fragment>
      <div className={'flex w-full'}>
        <BaseTable table={table} pagination={apiData?.pagination} />
      </div>
      {currentId && (
        <DetailsModal eventId={currentId} isOpen={isOpen} onClose={onClose} />
      )}
    </Fragment>
  )
}

export { Table }
