import { knwAPI } from '@/utils/api/knw'
import {
  GetPostResponseInterface,
  GetPostsResponseInterface,
  PostInterface,
} from '@/features/posts/redux/types'
import { setPaginatedCache, setSingleObjectCache } from '@/utils/api/rtkHelper'

export const {
  useGetPostsQuery,
  useGetPostQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
  useDeleteImageInPostMutation,
} = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query<GetPostsResponseInterface['data'], string>({
      query: (query) => `/api/admin/posts?${query}`,
      transformResponse: (response: GetPostsResponseInterface) => response.data,
      providesTags: (result) => setPaginatedCache('Post', result),
    }),
    getPost: builder.query<PostInterface, number | string>({
      query: (id) => `/api/admin/posts/${id}`,
      transformResponse: (response: GetPostResponseInterface) => response.data,
      providesTags: (result) => setSingleObjectCache('Post', result),
    }),
    updatePost: builder.mutation<void, { id: string | number; body: FormData }>(
      {
        query: ({ id, body }) => ({
          url: `/api/admin/posts/${id}`,
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        invalidatesTags: (_, error, { id }) => [
          { type: 'Post', id: 'LIST' },
          { type: 'Post', id },
        ],
      }
    ),
    deletePost: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/api/admin/posts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Post', id: 'LIST' }],
    }),
    deleteImageInPost: builder.mutation<
      void,
      { id: string | number; postId: number | string }
    >({
      query: ({ id, postId }) => ({
        url: `/api/admin/posts/${postId}/images/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, { postId }) => [
        { type: 'Post', id: postId },
        { type: 'Post', id: 'LIST' },
      ],
    }),
  }),
})
