import { knwAPI } from '@/utils/api/knw'
import {
  AgreementInterface,
  GetAgreementGroupsResponseInterface,
  GetAgreementResponseInterface,
  GetAgreementsResponseInterface,
} from '@/features/agreements/redux/types'
import { setArrayCache, setSingleObjectCache } from '@/utils/api/rtkHelper'

export const {
  useGetAgreementsQuery,
  useGetAgreementQuery,
  useStoreAgreementMutation,
  useDeleteAgreementMutation,
  useGetAgreementGroupsQuery,
} = knwAPI.injectEndpoints({
  endpoints: (build) => ({
    getAgreements: build.query<AgreementInterface[], void>({
      query: () => '/api/admin/agreements',
      transformResponse: (response: GetAgreementsResponseInterface) =>
        response.data,
      providesTags: (result) => setArrayCache('Agreement', result),
    }),
    getAgreement: build.query<AgreementInterface, string | number>({
      query: (id) => `/api/admin/agreements/${id}`,
      transformResponse: (response: GetAgreementResponseInterface) =>
        response.data,
      providesTags: (result) => setSingleObjectCache('Agreement', result),
    }),
    storeAgreement: build.mutation<void, FormData>({
      query: (data) => ({
        url: '/api/admin/agreements',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: [{ type: 'Agreement', id: 'LIST' }],
    }),
    deleteAgreement: build.mutation<void, number | string>({
      query: (id) => ({
        url: `/api/admin/agreements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Agreement', id: 'LIST' }],
    }),
    getAgreementGroups: build.query<
      GetAgreementGroupsResponseInterface['data'],
      void
    >({
      query: () => '/api/admin/agreement-groups',
      transformResponse: (response: GetAgreementGroupsResponseInterface) =>
        response.data,
      providesTags: (result) => setArrayCache('AgreementGroup', result),
    }),
  }),
})
