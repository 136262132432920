import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll'
import { AutocompleteItem } from '@nextui-org/react'
import { each } from 'lodash'
import { Key } from '@react-types/shared'
import { FormTypes } from './Form.types'
import {
  StoreUserPropsInterface,
  UpdateUserPropsInterface,
} from '@/features/users/redux/types'
import { useValidation } from '@/utils/hooks/useValidation'
import { Autocomplete, Button, FormControl, TextInput } from '@/components'
import { useInfiniteRolesQuery } from '@/features/roles/redux'
import { Nullable } from '@/utils/types'

const Form: FC<FormTypes> = ({
  data,
  backendErrors,
  onSubmit,
  validation,
}): ReactNode => {
  const { t } = useTranslation(['form', 'validation'])
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    new validation(),
    t
  )
  const [inputValue, setInputValue] = useState('')
  const {
    hasMore,
    data: roles,
    isLoading,
    handleNext,
  } = useInfiniteRolesQuery(inputValue)
  const [_, scrollerRef] = useInfiniteScroll({
    hasMore,
    isEnabled: true,
    onLoadMore: handleNext,
  })

  const { control, setValue, setError, handleSubmit } = useForm<
    StoreUserPropsInterface | UpdateUserPropsInterface
  >({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (backendErrors) {
      const errors = resolveValidationErrors(backendErrors)
      each(errors, (value, key) => {
        setError(key as keyof StoreUserPropsInterface, {
          type: 'manual',
          message: value,
        })
      })
    }
  }, [setError, backendErrors])

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('email', data.email)

      if (data.role) {
        setInputValue(data.role.name)
        setValue('role_id', data.role.id)
      }
    }
  }, [data])

  const handleChangeRole = (key: Nullable<Key>) => {
    if (!key) setValue('role_id', null)

    setValue('role_id', key as number)

    const role = roles.find((role) => role.id === Number(key))

    setInputValue(role?.name ?? '')
  }

  return (
    <div className={'flex flex-col gap-y-6'}>
      <div className={'grid grid-cols-4 gap-4'}>
        <Controller
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'name'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                label={t('form:labels.name')}
                {...field}
              />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'email'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                type={'email'}
                label={t('form:labels.email')}
                {...field}
              />
            </FormControl>
          )}
          name={'email'}
          control={control}
        />
        <Controller
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'password'} errors={errors}>
              <TextInput
                type={'password'}
                isInvalid={!!error}
                label={t('form:labels.password')}
                value={field.value ?? ''}
                placeholder={t('form:placeholders.password')}
                onChange={field.onChange}
              />
            </FormControl>
          )}
          name={'password'}
          control={control}
        />
        <Controller
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'password_confirmation'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                onChange={field.onChange}
                value={field.value ?? ''}
                type={'password'}
                placeholder={t('form:placeholders.password_confirmation')}
                label={t('form:labels.password_confirmation')}
              />
            </FormControl>
          )}
          name={'password_confirmation'}
          control={control}
        />
        <Controller
          render={({ field, formState: { errors } }) => (
            <FormControl name={'role_id'} errors={errors}>
              <Autocomplete
                items={roles}
                isLoading={isLoading}
                scrollRef={scrollerRef}
                label={t('form:labels.role')}
                placeholder={t('form:placeholders.type_to_search_role')}
                inputValue={inputValue}
                selectedKey={field.value ? field.value.toString() : null}
                onInputChange={setInputValue}
                onSelectionChange={handleChangeRole}
              >
                {(role) => (
                  <AutocompleteItem key={role.id.toString()}>
                    {role.name}
                  </AutocompleteItem>
                )}
              </Autocomplete>
            </FormControl>
          )}
          name={'role_id'}
          control={control}
        />
      </div>
      <div className={'flex'}>
        <Button type={'button'} onClick={handleSubmit(onSubmit)}>
          {t('form:buttons.save')}
        </Button>
      </div>
    </div>
  )
}

export { Form }
