import { TFunction } from 'i18next'
import * as yup from 'yup'
import {
  StoreUserPropsInterface,
  UpdateUserPropsInterface,
} from '@/features/users/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class UpdateUserValidation
  implements ValidationInterface<UpdateUserPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<UpdateUserPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      email: yup
        .string()
        .email(t('validation:email_invalid'))
        .required(t('validation:required')),
      password: yup
        .string()
        .min(8, t('validation:min_length', { length: 8 }))
        .nullable()
        .transform((value) => (value === '' ? null : value)),
      password_confirmation: yup
        .string()
        .oneOf(
          [yup.ref('password'), ''],
          t('validation:password_confirmation_invalid')
        )
        .nullable()
        .when('password', {
          is: (value: string | null) => (value ? value.length > 0 : false),
          then: (yup) => yup.required(t('validation:required')),
        })
        .transform((value) => (value === '' ? null : value)),
      role_id: yup.number().nullable(),
    })
  }

  defaultValues(): StoreUserPropsInterface {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role_id: null,
    }
  }
}

export { UpdateUserValidation }
