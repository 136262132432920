import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { UpdateCategoryValidation } from './rules/UpdateCategoryValidation'
import {
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from '@/features/categories/redux/categoryAPI'
import {
  StoreCategoryPropsInterface,
  UpdateCategoryPropsInterface,
} from '@/features/categories/redux/types'
import { Card } from '@/components'
import { Form } from '@/features/categories/resources/components'

export const CategoryEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(['categories'])
  const { id } = useParams<{ id: string }>() as { id: string }
  const [updateCategory] = useUpdateCategoryMutation()
  const { data } = useGetCategoryQuery(id)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (
    data: UpdateCategoryPropsInterface | StoreCategoryPropsInterface
  ) => {
    const formData = new FormData()

    formData.append('name', data.name)

    if (data.icon) {
      formData.append('icon', data.icon, data.icon.name)
    }

    if (data.color) {
      formData.append('color', data.color)
    }

    formData.append('_method', 'PUT')

    try {
      await updateCategory({ id, body: formData }).unwrap()
      enqueueSnackbar(t('categories:create.success'), { variant: 'success' })
      navigate('/categories')
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('categories:edit.title')}
      </span>
      <Card>
        <Form
          data={data}
          onSubmit={onSubmit}
          validation={UpdateCategoryValidation}
        />
      </Card>
    </div>
  )
}
