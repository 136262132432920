import { Card as BaseCard, extendVariants } from '@nextui-org/react'
import { forwardRef } from 'react'
import { ExtendedCardPropsType } from './Card.types'
import { cn } from '@/utils/helpers/cn'

const ExtendedCard = extendVariants(BaseCard, {
  variants: {
    color: {
      primary: {
        base: 'bg-background-secondary',
      },
    },
  },
})

const Card = forwardRef<HTMLDivElement, ExtendedCardPropsType>(
  ({ className, noPadding, children, ...rest }, ref) => {
    const classNames = cn(
      className,
      'py-4.3 px-6 border border-neutral-600 shadow-sm rounded-md bg-white mb-8',
      {
        '!p-0': noPadding,
      }
    )

    return (
      <ExtendedCard ref={ref} className={classNames} {...rest}>
        {children}
      </ExtendedCard>
    )
  }
)

Card.displayName = 'Card'

export { Card }
