import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { VendingMachineCabinetInterface } from '../../types'

const CabinetsTable: FC<{
  cabinets: VendingMachineCabinetInterface[]
}> = ({ cabinets }): ReactNode => {
  const { t } = useTranslation(['form', 'utils', 'vending_machines'])

  return (
    <div className={'flex flex-col gap-4'}>
      <span className={'text-lg font-medium'}>
        {t('vending_machines:details.cabinets.title')}
      </span>
      <Table>
        <TableHeader>
          <TableColumn>{t('form:labels.id')}</TableColumn>
          <TableColumn>{t('form:labels.door_status')}</TableColumn>
          <TableColumn>{t('form:labels.code')}</TableColumn>
        </TableHeader>
        <TableBody>
          {cabinets.map((cabinet) => (
            <TableRow key={cabinet.id}>
              <TableCell>{cabinet.id}</TableCell>
              <TableCell>{t(`utils:general.${cabinet.door_status}`)}</TableCell>
              <TableCell>{cabinet.code}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export { CabinetsTable }
