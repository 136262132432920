import { UseFormSetValue } from 'react-hook-form'
import {
  PostInterface,
  UpdatePostRequestInterface,
} from '@/features/posts/redux/types'
import { Carbon } from '@/utils/carbon'

export const associateValues = (
  data: PostInterface,
  setValue: UseFormSetValue<UpdatePostRequestInterface>
): void => {
  setValue('description', data.description)
  setValue('product_id', data.product_id)
  setValue('category_id', data.category_id)
  setValue('price', data.price)
  setValue('quantity', data.quantity)
  setValue('is_prow_wps', data.is_prow_wps)
  setValue('production_place', data.production_place)
  setValue('harvest_date', data.harvest_date)
  setValue('expired_at', data.expired_at)
  setValue('title', data.title)
  setValue('unit', data.unit)

  if (data.expired_at) {
    setValue(
      'expired_at',
      new Carbon().parse(data.expired_at).format('yyyy-MM-dd')
    )
  }
}
