import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CategoryInterface } from '@/features/categories/redux/types'
import { Card } from '@/components'

type Props = {
  data: Omit<CategoryInterface, 'tree'>
  parent?: boolean
}

export const Details: React.FC<Props> = ({ data, parent }): React.ReactNode => {
  const { t } = useTranslation(['form', 'categories'])

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {parent
            ? t('categories:show.parent_details')
            : t('categories:show.details')}
        </span>
        <div className={'flex flex-col gap-y-2'}>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>{t('form:labels.name')}</span>
            <span>{data.name}</span>
          </div>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>{t('form:labels.icon')}</span>
            <img src={data.url || ''} alt={data.name} className={'size-4'} />
          </div>
        </div>
      </div>
    </Card>
  )
}
