import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SaveRoleRequestInterface } from '@/features/roles/redux/types'
import {
  useGetRoleQuery,
  useUpdateRoleMutation,
} from '@/features/roles/redux/roleAPI'
import { Card } from '@/components'
import { Form } from '@/features/roles/resources/components'

export const RolesEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(['roles'])
  const { id } = useParams<{ id: string }>() as { id: string }
  const [updateRole] = useUpdateRoleMutation()
  const { data } = useGetRoleQuery(id)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = async (data: SaveRoleRequestInterface) => {
    try {
      await updateRole({ id, body: data })
      enqueueSnackbar(t('roles:edit.success'), { variant: 'success' })
      navigate('/roles')
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>{t('roles:edit.title')}</span>
      <Card>
        <Form data={data} onSubmit={handleSubmit} />
      </Card>
    </div>
  )
}
