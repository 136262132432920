import { FC, Fragment, ReactNode } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { serialize } from 'object-to-formdata'
import { useSnackbar } from 'notistack'
import { UpdatePostValidation } from '../rules/UpdatePostValidation'
import { LocationForm } from './LocationForm'
import { BasicDataForm } from './BasicDataForm'
import { ImageForm } from './ImageForm'
import { useUpdatePostMutation } from '@/features/posts/redux/postAPI'
import { PostInterface } from '@/features/posts/redux/types'
import { useValidation } from '@/utils/hooks/useValidation'
import { Button } from '@/components'

type Props = {
  data?: PostInterface
  id: number | string
}

export const BasicForm: FC<Props> = ({ data, id }): ReactNode => {
  const { t } = useTranslation(['form', 'validation', 'utils', 'posts'])
  const { schema, defaultValues } = useValidation(new UpdatePostValidation(), t)
  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const [updatePost] = useUpdatePostMutation()
  const { enqueueSnackbar } = useSnackbar()

  console.log(methods.formState.errors)

  const onSubmit = async (data: typeof defaultValues) => {
    const formData = serialize(data, {
      nullsAsUndefineds: true,
      booleansAsIntegers: true,
      indices: true,
    })

    formData.append('_method', 'PUT')

    try {
      await updatePost({
        id,
        body: formData,
      }).unwrap()
      enqueueSnackbar(t('posts:edit.success'), { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(t('utils:errors.something_went_wrong'), {
        variant: 'error',
      })
    }
  }

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={'grid lg:grid-cols-2 gap-4'}
        >
          <BasicDataForm data={data} />
          <div className={'flex flex-col'}>
            <LocationForm data={data} />
            <ImageForm data={data} />
          </div>
          <div className={'pt-4'}>
            <Button type={'submit'} color={'success'}>
              {t('form:buttons.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Fragment>
  )
}
