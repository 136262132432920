import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RoleInterface } from '@/features/roles/redux/types'
import { Card } from '@/components'

type Props = {
  role: RoleInterface
}

export const RoleData: React.FC<Props> = ({ role }): React.ReactNode => {
  const { t } = useTranslation(['form'])

  return (
    <Card>
      <div className={'flex flex-col gap-y-6'}>
        <span className={'text-lg font-medium'}>
          {t('users:show.role_data')}
        </span>
        <div className={'flex flex-col gap-y-2'}>
          <div className={'grid grid-cols-2 gap-4'}>
            <span className={'font-medium'}>{t('form:labels.name')}</span>
            <span>{role.name}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}
