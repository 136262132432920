import React, { FC, useState } from 'react'
import { Selection, SelectItem } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { useGetAgreementGroupsQuery } from '@/features/agreements/redux/agreementAPI'
import { Selectv2 } from '@/components'
import { ComponentSelectProps } from '@/utils/types'

const SelectAgreementsv2: FC<ComponentSelectProps> = ({
  onChange,
  label,
  placeholder,
}) => {
  const { data: agreements = [], isLoading } = useGetAgreementGroupsQuery()
  const [selectedAgreements, setSelectedAgreements] = useState<Selection>(
    new Set([])
  )
  const { t } = useTranslation()
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(event)
  }

  return (
    <div className='flex flex-col'>
      <Selectv2
        label={label}
        selectionMode='multiple'
        placeholder={placeholder}
        selectedKeys={selectedAgreements}
        onChange={handleSelectChange}
        onSelectionChange={setSelectedAgreements}
        isLoading={isLoading}
        className='max-w-xs'
        listboxProps={{
          emptyContent: t('agreements:select.no_agreements_found'),
        }}
      >
        {agreements.map((agreement) => (
          <SelectItem key={agreement.id} value={agreement.id}>
            {agreement.name}
          </SelectItem>
        ))}
      </Selectv2>
    </div>
  )
}

export { SelectAgreementsv2 }
