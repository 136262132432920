import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  ChangePasswordPropsInterface,
  ForgotPasswordPropsInterface,
  GetAuthUserResponseInterface,
  LogInPropsInterface,
  LogInResponseInterface,
  ResetPasswordPropsInterface,
} from '@/features/auth/redux/types'
import { knwAPI, url } from '@/utils/api/knw'

export const loginMutation = createAsyncThunk<
  LogInResponseInterface,
  LogInPropsInterface
>('auth/login', async (data, thunkAPI) => {
  const response = await axios.post(`${url}/api/v1/auth/login`, data)

  if (response.data && response.data.code === 200) {
    return response.data
  }

  return thunkAPI.rejectWithValue({
    error: null,
    code: response.data.code,
  })
})

export const forgotPasswordMutation = createAsyncThunk<
  void,
  ForgotPasswordPropsInterface
>('auth/forgot-password', async (data, thunkAPI) => {
  const response = await axios.post(`${url}/api/v1/auth/forgot-password`, data)

  if (response.status !== 200) {
    return thunkAPI.rejectWithValue({
      error: null,
      code: response.data.code,
    })
  }
})

export const resetPasswordMutation = createAsyncThunk<
  void,
  ResetPasswordPropsInterface
>('auth/reset-password', async (data, thunkAPI) => {
  const response = await axios.post(`${url}/api/v1/auth/reset-password`, data)

  if (response.status !== 200) {
    return thunkAPI.rejectWithValue({
      error: null,
      code: response.data.code,
    })
  }
})

export const { useGetMeQuery, useLogoutMutation } = knwAPI.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<GetAuthUserResponseInterface['data'], void>({
      query: () => `/api/v1/auth/me`,
      transformResponse: (response: GetAuthUserResponseInterface) =>
        response.data,
      providesTags: [{ type: 'User', id: 'Me' }],
    }),
    changePassword: builder.query<void, ChangePasswordPropsInterface>({
      query: (data) => ({
        url: `/api/v1/auth/change-password`,
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `/api/v1/auth/logout`,
        method: 'POST',
      }),
    }),
  }),
})
