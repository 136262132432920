import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './components'
import { Query } from '@/utils/query'
import { usePermissions } from '@/utils/hooks/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { Button, Card } from '@/components'

export const ProductListScreen = (): React.ReactNode => {
  const { t } = useTranslation(['products', 'form'])
  const query = useMemo(() => new Query(), [])
  const { hasPermission } = usePermissions()

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <div className={'flex flex-row-reverse'}>
        {hasPermission(PermissionEnum.PRODUCT_SAVE) && (
          <Button
            className={'flex items-center gap-x-2'}
            as={'a'}
            href={'/products/create'}
          >
            <FontAwesomeIcon icon={faPlus} className={'text-white'} />
            <span>{t('products:list.create')}</span>
          </Button>
        )}
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>{t('products:list.title')}</span>
          </div>
          <Table query={query} />
        </div>
      </Card>
    </div>
  )
}
