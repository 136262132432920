import { FC, Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react'
import { useSnackbar } from 'notistack'
import { match } from 'ts-pattern'
import { useChangeStatusMutation, useGetEventQuery } from '../../repositories'
import { ModalComponentProps } from '@/utils/types'
import { Carbon } from '@/utils/carbon'
import { EventStatusEnum } from '@/features/Events/enums'

const DetailsModal: FC<
  ModalComponentProps & {
    eventId: number
  }
> = ({ eventId, ...rest }): ReactNode => {
  const { data, isLoading } = useGetEventQuery(eventId)
  const { t } = useTranslation(['form', 'events', 'users'])
  const [updateStatus, { isLoading: isChanging }] = useChangeStatusMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeStatus = async (status: EventStatusEnum) => {
    try {
      await updateStatus({ id: eventId, status }).unwrap()

      match(status)
        .with(EventStatusEnum.APPROVED, () =>
          enqueueSnackbar(t('events:list.notifications.accepted'), {
            variant: 'success',
          })
        )
        .with(EventStatusEnum.REJECTED, () =>
          enqueueSnackbar(t('events:list.notifications.rejected'), {
            variant: 'error',
          })
        )

      rest.onClose?.()
    } catch (error) {
      enqueueSnackbar(t('utils:alerts.errors.500'), {
        variant: 'error',
      })
    }
  }

  return (
    <Modal {...rest} size={'2xl'} placement={'center'}>
      <ModalContent>
        {isLoading || !data ? (
          <ModalBody>
            <div className={'flex justify-center items-center h-[200px]'}>
              <Spinner />
            </div>
          </ModalBody>
        ) : (
          <Fragment>
            <ModalHeader>
              <span>{t('events:details.title', { title: data.title })}</span>
            </ModalHeader>
            <ModalBody>
              <div className={'flex flex-col gap-1'}>
                <span className={'font-semibold'}>
                  {t('form:labels.title')}
                </span>
                <span>{data.title}</span>
              </div>
              <div className={'flex flex-col gap-1'}>
                <span className={'font-semibold'}>
                  {t('form:labels.description')}
                </span>
                <span>{data.message}</span>
              </div>
              <div className={'flex flex-col gap-1'}>
                <span className={'font-semibold'}>{t('form:labels.type')}</span>
                <span>{t(`events:types.${data.type}`)}</span>
              </div>
              <div className={'flex flex-col gap-1'}>
                <span className={'font-semibold'}>
                  {t('form:labels.creator')}
                </span>
                <div className={'flex flex-col'}>
                  <span>{data.creator?.name}</span>
                  <span>{data.creator?.email}</span>
                  <span>{t(`users:user_types.${data.creator?.type}`)}</span>
                </div>
              </div>
              <div className={'flex flex-col gap-1'}>
                <span className={'font-semibold'}>
                  {t('form:labels.created_at')}
                </span>
                <span>
                  {new Carbon(data.created_at).format('dd.MM.yyyy HH:ii')}
                </span>
              </div>
            </ModalBody>
            <ModalFooter className={'justify-start'}>
              <Button
                isLoading={isChanging}
                fullWidth
                onPress={() => handleChangeStatus(EventStatusEnum.APPROVED)}
                className={'text-white'}
                color={'success'}
              >
                {t('form:buttons.accept')}
              </Button>
              <Button
                isLoading={isChanging}
                fullWidth
                onPress={() => handleChangeStatus(EventStatusEnum.REJECTED)}
                className={'text-white'}
                color={'danger'}
              >
                {t('form:buttons.reject')}
              </Button>
              <Button fullWidth>{t('form:buttons.close')}</Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  )
}

export { DetailsModal }
