import * as yup from 'yup'
import { UpdateVendingMachineRequestInterface } from '../types'
import { VendingMachineInternalStatusEnum } from '../enums'
import { ValidationInterface } from '@/utils/types'

const editVendingMachineValidation: ValidationInterface<UpdateVendingMachineRequestInterface> =
  {
    schema: (t) =>
      yup.object().shape({
        device_name: yup.string().required(t('validation:required')),
        internal_status: yup
          .mixed<VendingMachineInternalStatusEnum>()
          .required(t('validation:required')),
        location: yup.object().shape({
          lat: yup.number().required(t('validation:required')),
          lon: yup.number().required(t('validation:required')),
        }),
        location_name: yup.string().required(t('validation:required')),
      }),

    defaultValues: {
      device_name: '',
      internal_status: VendingMachineInternalStatusEnum.ACTIVE,
      location: {
        lat: 52.22977,
        lon: 21.01178,
      },
      location_name: '',
    },
  }

export { editVendingMachineValidation }
