import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ResetPasswordPropsInterface } from '@/features/auth/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class ResetPasswordValidation
  implements ValidationInterface<ResetPasswordPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<ResetPasswordPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      email: yup
        .string()
        .email(t('validation:email_invalid'))
        .required(t('validation:required')),
      password: yup
        .string()
        .min(8, t('validation:min_length', { length: 8 }))
        .required(t('validation:required')),
      password_confirmation: yup
        .string()
        .oneOf(
          [yup.ref('password'), ''],
          t('validation:password_confirmation_invalid')
        )
        .required(t('validation:required')),
      token: yup.string().required(t('validation:required')),
    })
  }

  defaultValues(): ResetPasswordPropsInterface {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      token: '',
    }
  }
}

export { ResetPasswordValidation }
