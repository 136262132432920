import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { StoreCategoryValidation } from './rules/StoreCategoryValidation'
import { useStoreCategoryMutation } from '@/features/categories/redux/categoryAPI'
import {
  StoreCategoryPropsInterface,
  UpdateCategoryPropsInterface,
} from '@/features/categories/redux/types'
import { Card } from '@/components'
import { Form } from '@/features/categories/resources/components'

export const CategoryCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['categories'])
  const [storeCategory] = useStoreCategoryMutation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (
    data: StoreCategoryPropsInterface | UpdateCategoryPropsInterface
  ) => {
    const formData = new FormData()

    formData.append('name', data.name)

    if (data.icon) {
      formData.append('icon', data.icon, data.icon.name)
    }

    if (data.color) {
      formData.append('color', data.color)
    }

    try {
      await storeCategory(formData).unwrap()
      enqueueSnackbar(t('categories:create.success'), {
        variant: 'success',
      })
      navigate('/categories')
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('categories:create.title')}
      </span>
      <Card>
        <Form validation={StoreCategoryValidation} onSubmit={onSubmit} />
      </Card>
    </div>
  )
}
