import { FC, ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectItem,
} from '@nextui-org/react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import {
  useGetVendingMachineQuery,
  useUpdateVendingMachineMutation,
} from '../../repositories'
import { editVendingMachineValidation } from '../../rules'
import { VendingMachineInternalStatusEnum } from '../../enums'
import { Location } from './Location'
import { ModalComponentProps } from '@/utils/types'
import { Button, FormControl, Selectv2, TextInput } from '@/components'

const EditModal: FC<
  ModalComponentProps & {
    deviceId: number
  }
> = ({ deviceId, ...rest }): ReactNode => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
    'posts',
    'vending_machines',
  ])
  const { data: response } = useGetVendingMachineQuery(deviceId)
  const { schema, defaultValues } = useMemo(() => {
    const { schema, defaultValues } = editVendingMachineValidation

    return {
      schema: schema(t),
      defaultValues,
    }
  }, [t])
  const methods = useForm<typeof defaultValues>({
    resolver: yupResolver(schema),
    defaultValues,
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  })
  const {
    formState: { errors },
    control,
    setValue,
  } = methods
  const [updateVendingMachine, { isLoading }] =
    useUpdateVendingMachineMutation()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (response) {
      if (response.location_name) {
        setValue('location_name', response.location_name)
      }

      if (response.device_name) {
        setValue('device_name', response.device_name)
      }

      setValue('internal_status', response.internal_status)

      if (response.location.lat && response.location.lon) {
        setValue('location.lat', response.location.lat)
        setValue('location.lon', response.location.lon)
      }
    }
  }, [response])

  const onSubmit = async (data: typeof defaultValues) => {
    try {
      await updateVendingMachine({
        data,
        id: deviceId,
      }).unwrap()

      enqueueSnackbar(t('vending_machines:edit.success'), {
        variant: 'success',
      })
      rest.onClose?.()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal {...rest} size={'2xl'} placement={'center'}>
      <ModalContent>
        <ModalHeader>
          <span>{t('vending_machines:edit.title')}</span>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <div className={'flex flex-col gap-4'}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl name={field.name} errors={errors}>
                    <TextInput
                      label={t('form:labels.device_name')}
                      {...field}
                      placeholder={t('form:placeholders.device_name')}
                      isInvalid={!!error}
                    />
                  </FormControl>
                )}
                name={'device_name'}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl name={field.name} errors={errors}>
                    <TextInput
                      label={t('form:labels.address')}
                      onValueChange={field.onChange}
                      value={field.value as string}
                      placeholder={t('form:placeholders.address')}
                      isInvalid={!!error}
                    />
                  </FormControl>
                )}
                name={'location_name'}
                control={control}
              />
              <Location />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl name={field.name} errors={errors}>
                    <Selectv2
                      label={t('form:labels.internal_status')}
                      onChange={field.onChange}
                      isInvalid={!!error}
                      selectedKeys={[field.value]}
                      placeholder={t('form:placeholders.internal_status')}
                    >
                      {Object.values(VendingMachineInternalStatusEnum).map(
                        (status) => (
                          <SelectItem key={status}>
                            {t(`vending_machines:internal_status.${status}`)}
                          </SelectItem>
                        )
                      )}
                    </Selectv2>
                  </FormControl>
                )}
                name={'internal_status'}
                control={control}
              />
            </div>
          </FormProvider>
        </ModalBody>
        <ModalFooter className={'justify-start'}>
          <Button
            isLoading={isLoading}
            color={'primary'}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t('form:buttons.save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export { EditModal }
