import { TFunction } from 'i18next'
import * as yup from 'yup'
import { StoreUserPropsInterface } from '@/features/users/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class StoreUserValidation
  implements ValidationInterface<StoreUserPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<StoreUserPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      email: yup
        .string()
        .email(t('validation:email_invalid'))
        .required(t('validation:required')),
      password: yup
        .string()
        .min(8, t('validation:min_length', { length: 8 }))
        .required(t('validation:required')),
      password_confirmation: yup
        .string()
        .oneOf(
          [yup.ref('password'), ''],
          t('validation:password_confirmation_invalid')
        )
        .required(t('validation:required')),
      role_id: yup.number().nullable(),
    })
  }

  defaultValues(): StoreUserPropsInterface {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role_id: null,
    }
  }
}

export { StoreUserValidation }
