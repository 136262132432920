import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  ForgotPasswordScreen,
  LoginScreen,
  ResetPasswordScreen,
} from './features/auth/resources'
import { PrivateRoutes } from '@/utils/routes/privateRoutes'
import { routes } from '@/utils/routes/privateRoutes/routes'
import { AuthRoutes } from '@/utils/routes/authRoutes'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthRoutes />}>
          <Route element={<LoginScreen />} path={'/login'} />
          <Route element={<ForgotPasswordScreen />} path={'/forgot-password'} />
          <Route element={<ResetPasswordScreen />} path={'/reset-password'} />
        </Route>
        <Route element={<PrivateRoutes routes={routes} />}>
          {routes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={<route.component />}
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
