import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AuthSliceInterface } from './types'
import { RootState } from '@/app/store'
import { loginMutation } from '@/features/auth/redux/authAPI'
import { PermissionEnum } from '@/features/permissions'

const initialState: AuthSliceInterface = {
  permissions: [],
  token: null,
  authenticated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null
      state.authenticated = false
    },
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload
      state.authenticated = true
    },
    setPermissions: (state, action: PayloadAction<PermissionEnum[]>) => {
      state.permissions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginMutation.fulfilled, (state, action) => {
      state.token = action.payload.data.token
      state.authenticated = true

      return state
    })
  },
})

export default authSlice.reducer

export const { logout, login, setPermissions } = authSlice.actions

export const getIsAuthenticated = createDraftSafeSelector(
  (state: RootState) => state.auth,
  (auth) => auth.authenticated
)
