import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './components'
import { Query } from '@/utils/query'
import { Card } from '@/components'

export const PostListScreen = (): ReactNode => {
  const { t } = useTranslation(['posts'])
  const query = useMemo(() => new Query(), [])

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>{t('posts:list.title')}</span>
          </div>
          <Table query={query} />
        </div>
      </Card>
    </div>
  )
}
