import {
  cn,
  DatePicker as BaseDatePicker,
  DatePickerProps,
} from '@nextui-org/react'
import _ from 'lodash'
import { forwardRef } from 'react'

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, ref) => {
    const { classNames, isInvalid, label, ...rest } = props

    return (
      <BaseDatePicker
        ref={ref}
        label={label}
        labelPlacement={'outside'}
        dateInputClassNames={{
          label: cn('text-sm font-bold text-neutral-900', classNames?.label),
          inputWrapper: cn(
            'border border-opaque !bg-white text-sm shadow-none',
            'focus-within:hover:bg-white hover:bg-white group-data-[focus=true]:bg-white group-data-[hover=true]:bg-white',
            {
              '!border-foundation-danger !text-foundation-danger': isInvalid,
            },
            classNames?.inputWrapper
          ),
          input: cn(
            'text-sm leading-5 placeholder-content-tertiary',
            classNames?.input
          ),
          ..._.omit(classNames, ['inputWrapper', 'input', 'label']),
        }}
        size={'lg'}
        radius={'sm'}
        isInvalid={isInvalid}
        {...rest}
      />
    )
  }
)

DatePicker.displayName = 'DateRangePicker'

export { DatePicker }
