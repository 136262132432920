import { useEffect, useRef } from 'react'
import {
  ClickAwayEventListener,
  ClickAwayListenerProps,
} from './ClickAwayListener.types'

const ClickAwayListener = ({
  children,
  onClickAway,
}: ClickAwayListenerProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: ClickAwayEventListener) => {
      const { target } = event
      if (wrapperRef.current && !wrapperRef.current.contains(target as Node)) {
        onClickAway()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClickAway])

  return <div ref={wrapperRef}>{children}</div>
}

export { ClickAwayListener }
