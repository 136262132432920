import { flexRender, Table as TableType } from '@tanstack/react-table'
import { Pagination } from './Pagination'
import { PaginationInterface } from '@/app/types'
import { cn } from '@/utils/helpers/cn'

type Props<T> = {
  table: TableType<T>
  pagination?: PaginationInterface
}

const BaseTable = <T extends NonNullable<unknown>>({
  table,
  pagination,
}: Props<T>) => {
  return (
    <div className={'overflow-auto w-full'}>
      <table className={'table-auto w-full text-left'}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={'border-b border-b-neutral-600'}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={cn(
                      'bg-neutral-100 px-[12px] py-[6px] text-xs font-[500] text-gray-50'
                    )}
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                    }}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className={'text-xs font-medium text-gray-700'}>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className={'border-b border-b-neutral-600 h-full'}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={cn(
                        'px-[12px] py-[6px] text-[12px] text-gray-700'
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {pagination && (
        <div className={'px-8 py-6'}>
          <Pagination
            onPageChange={table.setPageIndex}
            totalPages={pagination.total_pages}
          />
        </div>
      )}
    </div>
  )
}

export { BaseTable }
