import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useStoreRoleMutation } from '@/features/roles/redux/roleAPI'
import { SaveRoleRequestInterface } from '@/features/roles/redux/types'
import { Card } from '@/components'
import { Form } from '@/features/roles/resources/components'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation'
import { isFetchBaseQueryError } from '@/utils/api/rtkHelper'

export const RolesCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['roles'])
  const [storeRole] = useStoreRoleMutation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveRoleRequestInterface>>()

  const handleSubmit = async (data: SaveRoleRequestInterface) => {
    try {
      await storeRole(data).unwrap()
      enqueueSnackbar(t('roles:create.success'), { variant: 'success' })
      navigate('/roles')
    } catch (error) {
      if (isFetchBaseQueryError(error) && error.status === 422) {
        setErrors(
          error.data as BackendValidationErrorInterface<SaveRoleRequestInterface>
        )
      }
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>{t('roles:create.title')}</span>
      <Card>
        <Form onSubmit={handleSubmit} errors={errors} />
      </Card>
    </div>
  )
}
