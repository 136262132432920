import { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardHeader } from '@nextui-org/react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { useGetVendingMachineQuery } from '../repositories'
import { BasicInfoTable, CabinetsTable, ProductsTable } from './partials'

const VendingMachineDetails = (): ReactNode => {
  const { t } = useTranslation(['vending_machines', 'utils', 'form'])
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data, isLoading } = useGetVendingMachineQuery(Number(id))
  const location: [number, number] = useMemo(
    () =>
      data && data.location.lat && data.location.lon
        ? [data.location.lat, data.location.lon]
        : [0, 0],
    [data]
  )

  if (!data || isLoading) {
    return (
      <div className={'p-4'}>
        <div
          className={'w-full h-36 bg-neutral-300 animate-pulse rounded-medium'}
        />
      </div>
    )
  }

  return (
    <div className={'flex flex-col p-8 gap-4 mb-24 lg:mb-0'}>
      <span className={'text-lg font-medium'}>
        {t('vending_machines:details.title', { name: data.device_name })}
      </span>
      <div className={'grid lg:grid-cols-2 gap-8'}>
        <BasicInfoTable device={data} />
        <div className={'flex flex-col'}>
          <div className={'flex flex-col gap-2'}>
            <Card shadow={'sm'}>
              <CardHeader>{data.location_name}</CardHeader>
              <CardBody>
                <div className={'h-[300px] w-full'}>
                  <MapContainer
                    center={location}
                    zoom={16}
                    scrollWheelZoom={false}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    <Marker position={location}>
                      <Popup></Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <ProductsTable products={data.products} />
      <CabinetsTable cabinets={data.cabinets} />
    </div>
  )
}

export { VendingMachineDetails }
