import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { ForgotPasswordValidation } from '../validations/ForgotPasswordValidation'
import { useAppDispatch } from '@/app/hooks'
import { forgotPasswordMutation } from '@/features/auth/redux/authAPI'
import { ForgotPasswordPropsInterface } from '@/features/auth/redux/types'
import { Button, FormControl, TextInput } from '@/components'
import { useValidation } from '@/utils/hooks/useValidation'

export const ForgotPasswordScreen = (): ReactNode => {
  const { t } = useTranslation(['auth', 'form', 'validation'])
  const { schema, defaultValues } = useValidation(
    new ForgotPasswordValidation(),
    t
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleForgotPassword = async (data: ForgotPasswordPropsInterface) => {
    try {
      await dispatch(forgotPasswordMutation(data)).unwrap()
      enqueueSnackbar(t('auth:forgot_password.success'), { variant: 'success' })
      navigate('/login')
    } catch (error) {
      /* empty */
    }
  }

  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  return (
    <Fragment>
      <div className={'flex flex-col gap-y-6'}>
        <Controller
          name={'email'}
          control={control}
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'email'} errors={errors}>
              <TextInput
                isInvalid={!!error}
                label={t('form:labels.email')}
                type={'email'}
                placeholder={t('form:placeholders.email')}
                {...field}
              />
            </FormControl>
          )}
        />
        <Button onClick={handleSubmit(handleForgotPassword)}>
          {t('auth:forgot_password.button')}
        </Button>
      </div>
    </Fragment>
  )
}
