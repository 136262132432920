import { FC, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Action, ActionStackProps } from './ActionStack.types'
import { cn } from '@/utils/helpers/cn'
import { usePermissions } from '@/utils/hooks/usePermission'

const ActionStack: FC<ActionStackProps> = ({ actions }) => {
  const { hasPermission } = usePermissions()

  const renderIcon = (action: Action) => {
    const { icon, onClick, buttonClassName, permission, condition } = action

    if (
      (condition === undefined || condition) &&
      (permission === undefined || hasPermission(permission))
    ) {
      return (
        <button
          className={cn(
            'size-6 rounded flex items-center justify-center',
            buttonClassName
          )}
          onClick={onClick}
        >
          <FontAwesomeIcon icon={icon} className={'text-white'} />
        </button>
      )
    }

    return <Fragment></Fragment>
  }

  return (
    <div className={cn('flex items-center gap-2')}>
      {actions.map((action, index) => (
        <Fragment key={index}>{renderIcon(action)}</Fragment>
      ))}
    </div>
  )
}

export { ActionStack }
