import { FC, ReactNode, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { Map as LeafletMap } from 'leaflet'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import { UpdateVendingMachineRequestInterface } from '../../types'
import { Coordinates, LocationMarkerInterface, Nullable } from '@/utils/types'
import 'leaflet/dist/leaflet.css'

const Location = (): ReactNode => {
  const { watch, setValue } =
    useFormContext<UpdateVendingMachineRequestInterface>()
  const { lat, lon } = watch('location')
  const ref = useRef<Nullable<LeafletMap>>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.panTo([lat, lon])
    }
  }, [lat, lon])

  const handleChange = (position: Coordinates) => {
    setValue('location', position)
  }

  return (
    <div className={'w-full h-[200px]'}>
      <MapContainer
        center={[lat, lon]}
        zoom={12}
        ref={ref}
        className={'h-full'}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <LocationMarker
          position={{
            lat,
            lon,
          }}
          onChange={handleChange}
        />
      </MapContainer>
    </div>
  )
}

const LocationMarker: FC<LocationMarkerInterface> = ({
  onChange,
  position,
}) => {
  const map = useMapEvents({
    click(e) {
      if (onChange) {
        onChange({
          lat: e.latlng.lat,
          lon: e.latlng.lng,
        })
      }
    },
  })

  useEffect(() => {
    map.panTo([position.lat, position.lon])
  }, [position, map])

  return (
    <Marker position={[position.lat, position.lon]}>
      <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup>
    </Marker>
  )
}

export { Location }
