import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SaveProductValidation } from './rules/SaveProductValidation'
import {
  ProductInterface,
  SaveProductPropsInterface,
} from '@/features/products/redux/types'
import { useValidation } from '@/utils/hooks/useValidation'
import { Button, FormControl, SearchCategory, TextInput } from '@/components'

type Props = {
  data?: ProductInterface
  onSubmit: (data: SaveProductPropsInterface) => void
}

export const Form: React.FC<Props> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(['form', 'validation'])
  const { schema, defaultValues } = useValidation(
    new SaveProductValidation(),
    t
  )

  const { control, handleSubmit, setValue } =
    useForm<SaveProductPropsInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('category_id', data.category_id)
    }
  }, [data])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={'flex flex-col gap-y-6 '}
    >
      <div className={'grid grid-cols-3 2xl:grid-cols-4 gap-4'}>
        <Controller
          render={({ field, formState: { errors }, fieldState: { error } }) => (
            <FormControl name={'name'} errors={errors}>
              <TextInput
                placeholder={t('form:placeholders.type_product_name')}
                isInvalid={!!error}
                label={t('form:labels.name')}
                {...field}
              />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({ field, formState: { errors } }) => (
            <FormControl name={'category_id'} errors={errors}>
              <SearchCategory
                label={t('form:labels.category')}
                initialInputValue={data?.category?.name}
                selectedKey={field.value}
                onSelectionChange={field.onChange}
                placeholder={t('form:placeholders.type_to_search_category')}
              />
            </FormControl>
          )}
          name={'category_id'}
          control={control}
        />
      </div>
      <div className={'flex'}>
        <Button type={'submit'}>{t('form:buttons.save')}</Button>
      </div>
    </form>
  )
}
